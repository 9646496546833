<template>
    <div class="w-full px-5 pt-3">
      <div class="flex justify-start items-center space-x-3 mb-1">
        <div class="flex" style="margin-left: auto">
                <div class="w-40">
                    <button
                    @click="inactivateAllApplications()"
                    class="rounded-md w-full text-white bg-gradient-to-r from-yellow-500 via-yellow-600 to-yellow-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium text-sm px-5 py-2.5 text-center"
                    >
                    Изменить статус
                    </button>
                </div>
        </div>

         
        </div>
        <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div class="w-2/4 bg-white rounded-lg p-4">
            <h2 class="font-bold text-blue-500 text-xl flex items-center">
                  <button @click="exitToModal()" class="pr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clip-rule="evenodd" />
                  </svg>
                  </button>
                  Форма добавления результата
          </h2>
          <hr>
            <label
            for="cover"
            class="block mt-5 mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >RESULT</label
          >
            <input
            id="title"
            rows="4"
            class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
            placeholder="Введите результат..."
            v-model="this.result"
          />
          <div class="">
          <label
            for="type"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Status</label
          >

          <div class="relative inline-block text-left">
            <div>
              <button
                @click="isStatusOpen = !isStatusOpen"
                class="inline-flex justify-between w-[200px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="options-menu"
                aria-haspopup="true"
                :aria-expanded="isStatusOpen"
              >
                {{ selectedStatusOption }}
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div
              v-show="isStatusOpen"
              class="origin-top-right absolute right-0 mt-2 w-[200px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div
                v-for="(option, index) in statusOptions"
                :key="index"
                class=""
                role="none"
              >
                <a
                  @click="selectStatusOption(option)"
                  href="#"
                  class="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  :class="
                    selectedStatusOption === option ? 'bg-gray-100 text-gray-900' : ''
                  "
                >
                  {{ option }}
                </a>
              </div>
            </div>
          </div>
        </div>
          <div class="flex float-right" style="margin-left: auto">
                <div class="w-40">
                    <button
                    @click="editApplicationResult()"
                    class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
                    >
                    EDIT
                    </button>
                </div>
        </div>
          

          </div>
        </div>
        <div class="overflow-x-auto mx-0">
          <div class="py-2 align-middle inline-block min-w-full">
            <div
              class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      ИД
                    </th>
                    <th
                      scope="col"
                      class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                    ИД ВАКАНСИИ
                    </th>
                    <th
                      scope="col"
                      class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      PINFL 
                    </th>
                    <th
                      scope="col"
                      class="w-60 px-1 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                    ФИО
                    </th>
                    <th
                      scope="col"
                      class="w-14 px-1 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                    EMAIL
                    </th>
                    <th
                      scope="col"
                      class="w-44 px-1 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                    ДАТА ВЫДАЧИ
                    </th>
                    <th
                      scope="col"
                      class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                    РЕЗУЛЬТАТ
                    </th>
                    <th
                      scope="col"
                      class="w-20 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                    СТАТУС
                    </th>
                    <th
                      scope="col"
                      class="w-20 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                    РЕЗЮМЕ
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(item, index) in paginatedData"
                    :key="index"
                  >
                    <td
                      class="whitespace-nowrap text-sm text-gray-500 font-mono border-r text-center"
                    >
                      {{ item.id }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p 
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 "
                      >
                      <!-- class="text-base font-bold whitespace-normal text-blue-500" tepaga p ni clasi-->
                        {{ item.vacancyId }}
                      </p>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                        {{ item.pinfl }}
                      </p>
                    </td>  
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                        {{ item.fullName }}
                      </p>
                    </td>   
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                        {{ item.email }}
                      </p>
                    </td>  
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                        {{ formatedDate(item.date) }}
                      </p>
                    </td> 
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                        {{ item.result }}
                      </p>
                    </td> 
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                        {{ item.status }}
                      </p>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                      <button
                        @click="DownloadFile(index)"
                        class="hover:text-blue-500">
                        <img src="../assets/pdf.png" alt="pdf" width="35"
                                       height="35">
                      </button>
                      </p>
                    </td>
                    <td
                      class="text-center pxwhitespace-nowrap text-sm text-gray-500 border-l"
                    >
                      <button
                        @click="getToEdit(index)"
                        class="hover:text-blue-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 transition ease-linear mx-auto"
                        >
                          <path
                            d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"
                          />
                          <path
                            d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"
                          />
                        </svg>
                      </button>
                    </td>
                    <td
                      class="text-center whitespace-nowrap text-sm text-gray-500 border-l"
                    >
                      <button
                        @click="deleteItem(index)"
                        class="hover:text-blue-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 transition ease-linear mx-auto"
                        >
                          <path
                            d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zm6.133 2.845a.75.75 0 011.06 0l1.72 1.72 1.72-1.72a.75.75 0 111.06 1.06l-1.72 1.72 1.72 1.72a.75.75 0 11-1.06 1.06L12 15.685l-1.72 1.72a.75.75 0 11-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </td>
                    <td
                      class="text-center px-6 whitespace-nowrap text-sm text-gray-500 border-l"
                    >
                    <div class="w-40">
                    <button
                    @click="testingForApplications(index)"
                    class="rounded-md w-full w- text-white bg-gradient-to-r via-gre from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-700 font-medium text-sm px-5 py-2.5 text-center"
                    >
                    Отправьте ссылку
                    </button>
                    </div>
                    </td>

                    <td
                      class="text-center px-6 whitespace-nowrap text-sm text-gray-500 border-l"
                    >
                    <div class="w-40">
                      <button
                      @click="rejectedforApplications(index)"
                      class="rounded-md w-full text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-700 font-medium text-sm px-5 py-2.5 text-center"
                      >
                      Отклонить
                    </button>
                  </div>
                    </td>
                  </tr>
                  <tr v-if="filteredData.length === 0">
                    <td
                      colspan="2"
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      Запрашиваемый элемент не найден.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex flex-col items-end mt-1">
                    <div class="flex text-gray-700 space-x-1">
                      <button
                        @click="previousPage"
                        :disabled="currentPage === 1"
                        class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-left w-6 h-6"
                        >
                          <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                      </button>
                      <span
                        v-for="pageNumber in pageNumbers"
                        :key="pageNumber"
                        class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md"
                      >
                        <button
                          @click="goToPage(pageNumber)"
                          :disabled="currentPage === pageNumber"
                          :class="{ active: currentPage === pageNumber }"
                          class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md"
                        >
                          {{ pageNumber }}
                        </button>
                      </span>
  
                      <button
                        @click="nextPage"
                        :disabled="currentPage === totalPages"
                        class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-right w-6 h-6"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </button>
                    </div>
                  </div>
          </div>
        </div>
      </div>

    <div v-show="loading">
    <LoadingPage message="Выполняется загрузка ..."></LoadingPage>
    </div>
  </template>
  
  <script>
  import LoadingPage from "./LoadingPage.vue";
  import { toast } from "vue3-toastify";
  export default {
    beforeRouteLeave(to, from, next) {
      sessionStorage.removeItem("VACANCY_ID");
      next();
    },
    components: {
      LoadingPage
    },
    data() {
      return {
        currentPage: 1,
        itemsPerPage: 7,
        searchQuery: "",
        applicationForVacancy: [],
        selectedStatusOption: "Выберите статус",
        isStatusOpen:false,
        isOpen: false,
        loading: true,
        editApplication: null,
        vacancyId: 0,
        statusOptions:null,
        bytes:[],
        result: null
      };
    },
    computed: {
      filteredData() {
        let filteredItems = this.applicationForVacancy;
  
        if (this.searchQuery) {
          filteredItems = filteredItems.filter((item) => {
            return item.keyWordRu
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });
        }
  
        return filteredItems;
      },
      paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.filteredData.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.filteredData.length / this.itemsPerPage);
      },
      pageNumbers() {
        const pages = [];
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
        return pages;
      },
    },
    methods: {
      async deleteItem(index) {
        let text = "Подтвердите удаление!";
        let deleteId = parseInt(this.applicationForVacancy[index].id);
        try {
          if (confirm(text) == true) {
            // eslint-disable-next-line
            const response = await this.axios.delete(
              `/api/Admin/ApplicationForVacancy/Delete${deleteId}`
            );
            toast.success("Тип нормативного документа успешно удален", {
              position: "top-center",
              transition: "flip",
              newestOnTop: true,
              toastStyle: {
                fontSize: "14px",
              },
            });
            this.loadTableData(this.vacancyId);
          } else {
            text = "Отмена удаления!";
          }
        } catch (error) {
          console.error(error);
        }
      },
      getToEdit(index) {
        this.editApplication = this.applicationForVacancy[index]
        this.result = this.editApplication.result;
        this.isOpen = !this.isOpen;
        this.selectStatusOption(this.editApplication.status);
      },
      formatedDate(item) {
      const dateTime = new Date(item);
      return dateTime.toLocaleDateString();
    },
      exitToModal(){
        this.result = null;
        this.isOpen = !this.isOpen;
      },
      selectStatusOption(option) {
            this.selectedStatusOption = option;
            this.vacancy.status = option;
            this.isStatusOpen = false;
            },
      async loadTableData(id) {
        this.axios
          .get("/api/Admin/ApplicationForVacancy/GetByVacancyId/" + id)
          .then((response) => {
            this.applicationForVacancy = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      async editApplicationResult(){
        try {
          this.editApplication.result = this.result;
            const response = await this.axios.post(
                "api/Admin/ApplicationForVacancy/UpdateApplicationResult",
                this.editApplication, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                toast.success("Результат изменен", {
                    position: "top-center",
                    transition: "flip",
                    newestOnTop: true,
                    toastStyle: {
                        fontSize: "14px",
                    },
                });
                this.editApplication = null;
                this.result = null;
                this.isOpen = !this.isOpen;
            }
        } catch (error) {
            toast.error("Ошибка при изменении результата", {
                position: "top-center",
                transition: "flip",
                newestOnTop: true,
                toastStyle: {
                    fontSize: "14px",
                },
            });
        }
      },
      async loadStatusData() {
            this.axios
                .get("api/Admin/ApplicationForVacancy/GetApplicationStatuses")
                .then((response) => {
                this.statusOptions = response.data;
                })
                .catch((error) => {
                console.log(error);
                });
            },
      async inactivateAllApplications(){
        try {
            const response = await this.axios.post(
                "api/Admin/ApplicationForVacancy/InactivateAllVacancyApplications",
                this.vacancyId, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                toast.success("Статусы изменены", {
                    position: "top-center",
                    transition: "flip",
                    newestOnTop: true,
                    toastStyle: {
                        fontSize: "14px",
                    },
                });
                this.loadTableData(this.vacancyId);
            }
        } catch (error) {
            toast.error("Ошибка при изменении статусов", {
                position: "top-center",
                transition: "flip",
                newestOnTop: true,
                toastStyle: {
                    fontSize: "14px",
                },
            });
        }
      },
      async testingForApplications(index){

        let application = this.applicationForVacancy[index]
        try {
            const response = await this.axios.post(
                "api/Admin/ApplicationForVacancy/SendMailAboutAnswerHr", application,{
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                toast.success("Ссылка на тест была отправлена на электронную почту.", {
                    position: "top-center",
                    transition: "flip",
                    newestOnTop: true,
                    toastStyle: {
                        fontSize: "14px",
                    },
                });
                this.loadTableData(this.vacancyId);
            }
        } catch (error) {
            toast.error("Ошибка при отправки", {
                position: "top-center",
                transition: "flip",
                newestOnTop: true,
                toastStyle: {
                    fontSize: "14px",
                },
            });
        }
      },
      async rejectedforApplications(index){
        let application = this.applicationForVacancy[index]
        try {
            const response = await this.axios.post(
                "api/Admin/ApplicationForVacancy/SendMailAboutRejectAnswerHr", application,{
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200) {
                toast.success("Отказ был отправлен на электронную почту.", {
                    position: "top-center",
                    transition: "flip",
                    newestOnTop: true,
                    toastStyle: {
                        fontSize: "14px",
                    },
                });
                this.loadTableData(this.vacancyId);
            }
        } catch (error) {
            toast.error("Ошибка при отправки", {
                position: "top-center",
                transition: "flip",
                newestOnTop: true,
                toastStyle: {
                    fontSize: "14px",
                },
            });
        }
      },
      async DownloadFile(index) {
    let application = this.applicationForVacancy[index];
    try {
        let response = await this.axios.get("/api/Admin/File/GetResumeFileById/" + application.applicationForVacancyFileId, {
            responseType: 'blob' // Set responseType to 'blob' to receive binary data
        });

        let blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the blob
        let url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        let link = document.createElement('a');
        link.href = url;
        link.download = application.applicationForVacancyFileName;

        // Simulate a click on the link to trigger the download
        link.click();

        // Clean up by revoking the object URL
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log(error);
    } finally {
        this.loading = false;
    }
},
      previousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      goToPage(pageNumber) {
        this.currentPage = pageNumber;
      },
    },
    mounted() {
      this.loadStatusData();
      if (sessionStorage.getItem("VACANCY_ID")) {
        this.vacancyId = JSON.parse(sessionStorage.getItem("VACANCY_ID"));
        
        this.loadTableData(this.vacancyId);
      }
    },
  };
  </script>
  
  <style>
  .active {
    background-color: #3b82f6;
    color: white;
  }
  .btn {
    margin-left: auto;
  }
  </style>