import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import globals from './globals.js'

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.request.use(
    (config) => {
      let token = sessionStorage.getItem(globals.TOKEN)
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    console.log(error.response.data)
    if (error.response.status === 401) {
        sessionStorage.clear();
        router.push('/')
    }
    return Promise.reject(error)
  })

const app = createApp(App);
app.config.globalProperties.$globals = globals
app.use(VueAxios, axios)
app.use(store)
app.use(router)
app.use(Vue3Toasity, {autoClose: 3000})
app.mount('#app')