<template>
    <div class="tabs px-5 pt-3">
      <div class="border rounded-md border-gray-300 shadow-sm">
        <div
          class="tab-nav flex justify-between items-center px-5 border-b justify"
        >
          <h2 class="font-bold text-blue-500 text-xl flex items-center">
            <button onclick="window.history.back()" class="pr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clip-rule="evenodd" />
                </svg>
                </button>
            Форма добавления истории
          </h2>
          <div class="py-2.5">
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              @click="activeTab = index"
              :class="[
                activeTab === index
                  ? 'text-white transition-all ease-in rounded-md bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br px-5 py-2.5 text-center'
                  : '',
                'px-4 py-2 focus:outline-none transition-colors ease-in rounded-md',
              ]"
            >
              {{ tab }}
            </button>
          </div>
        </div>
        <div class="tab-content border-0 px-5">
          <div v-show="activeTab === 0" class="tab-pane py-2 bg-white">
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Текст(на русском)</label
            >
            <textarea
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите текст..."
              v-model="this.history.textRu"
            >
            </textarea>
  
            
          </div>
          <div v-show="activeTab === 1" class="tab-pane py-2 bg-white">
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Текст(на узбекском)</label
            >
            <textarea
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите текст..."
              v-model="this.history.textUz"
            >
            </textarea>
          </div>
          <div v-show="activeTab === 2" class="tab-pane py-2 bg-white">
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Текст(на английском)</label
            >
            <textarea
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите текст..."
              v-model="this.history.textEn"
            >
            </textarea>
          </div>
        </div>
        <div class="border-t border-gray-300 w-full"></div>
        <div class="px-[16px] py-4 rounded-b-md flex bg-gray-200/50">
            <div class="basis-1/5">
          <label
            for="cover"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Год добавления</label
          >
          <div class="items-center space-x-3">
            <div class="relative inline-block text-left">
                <input
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите год..."
              v-model="this.history.year"
            />

              
            </div>
          </div>
        </div>

        </div>
      </div>
  
      <div class="flex float-right mt-5 space-x-5">
        <div class="w-40">
          <button
            @click="clearForm()"
            class="rounded-md w-full text-white bg-gradient-to-r from-slate-500 via-gray-600 to-gray-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-gray-800 font-medium text-sm px-5 py-2.5 text-center"
          >
            Сбросить
          </button>
        </div>
        <div class="w-40" v-if="isEditable">
          <button
            @click="updateOrAdd()"
            class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
          >
            Изменить
          </button>
        </div>
        <div v-else class="w-40">
          <button
            @click="updateOrAdd()"
            class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
          >
            Добавить
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { toast } from "vue3-toastify";
  export default {
    beforeRouteLeave(to, from, next) {
      sessionStorage.removeItem('CURRENT_TABLE'); 
      sessionStorage.removeItem('CURRENT_STATE'); 
      next();
    },
    data() {
      return {
        history: {
          id: 0,
          year: null,
          languageEn: "en",
          languageRu: "ru",
          languageUz: "uz",
          textEn: null,
          textUz: null,
          textRu: null
        },
        activeTab: 0,
        tabs: ["На русском", "На узбекском", "На английском"],
      };
    },
    methods: {
      clearForm() {
        this.history = {
          id: 0,
          year: null,
          languageEn: null,
          languageRu: null,
          languageUz: null,
          textEn: null,
          textUz: null,
          textRu: null
        };
      },
      async updateOrAdd() {
        try {
          const response = await this.axios.post(
            "/api/Admin/History/UpdateOrAdd",
            this.history,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            toast.success("История успешно добавлена!", {
              position: "top-center",
              transition: "flip",
              newestOnTop: true,
              toastStyle: {
                fontSize: "14px",
              },
            });
            this.clearForm();
            this.$router.push("/allHistories")
          }
        } catch (error) {
          toast.error("Ошибка в добавлении истории!", {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
            toastStyle: {
              fontSize: "14px",
            },
          });
        }
      },
    },
    mounted() {
      if (sessionStorage.getItem("CURRENT_TABLE")) {
        this.isEditable = true;
        this.history = JSON.parse(sessionStorage.getItem("CURRENT_TABLE"));
      }
    },
  };
  </script>
  