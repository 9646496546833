<template>
  <nav class="fixed top-0 h-20 z-50 w-full bg-white shadow-md">
    <div class="px-3 lg:px-5 lg:pl-3">
      <div class="flex items-center justify-between h-20">
        <div class="flex items-center justify-start">
          <button
            aria-controls="logo-sidebar"
            type="button"
            class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          >
            <span class="sr-only">Open sidebar</span>
            <svg
              class="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                fill-rule="evenodd"
                d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
              ></path>
            </svg>
          </button>
          <div class="flex items-center justify-center">
            <img src="../assets/logoCSD.svg" alt="" class="scale-50" />
            <p
              class="text-xs uppercase whitespace-nowrap dark:text-white font-thin"
            >
              панель администратора <br />
              сайта uzcsd.uz
            </p>
          </div>
        </div>
        <div class="flex items-center space-x-2">
          <div class="text-right font-thin uppercase text-xs">
            <h1 class="text-blue-500">
              {{ userData.name}}
            </h1>
            <h1>{{ userData.role}}</h1>
          </div>
          <div
            class="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600"
          >
            <svg @click="showAccount()"
              class="absolute w-12 h-12 text-gray-400 -left-1 cursor-pointer"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div id="account" class="absolute right-1 border p-2 w-[150px] mt-1 rounded-lg hidden bg-white shadow-lg">
        <div @click="callPage('')" class="flex items-center cursor-pointer space-x-2 text-slate-600 hover:text-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
            <p class="text-lg">Аккаунт</p>
        </div>
        <div @click="logOut()" class="flex items-center cursor-pointer space-x-2 text-slate-600 hover:text-blue-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
          </svg>
            <p class="text-lg">Выйти</p>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
const storedData = sessionStorage.getItem("userData");
const userData = JSON.parse(storedData);
</script>

<script>
export default {
  data(){
    return{

    }
  },
  methods:{
    showAccount(){
      const elem = document.getElementById('account');
      elem.classList.contains('hidden') ? elem.classList.remove('hidden') : elem.classList.add('hidden');
    },
    logOut(){
      sessionStorage.clear();
      this.$router.push({path:"/"})
    }
  }
}
</script>
