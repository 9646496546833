<template>
  <div v-if="loading">
    <LoadingPage message="Выполняется загрузка ..."></LoadingPage>
  </div>

  <div v-else>
    <div class="tabs px-5">
      <div class="rounded-md pb-2">
        <div class="tab-nav flex justify-between items-center">
          <h2 class="font-bold text-blue-500 text-2xl">DEPO Новости</h2>
          <div class="py-2.5">
            <button
              v-for="(tab, index) in tabs"
              :key="index"
              @click="activeTab = index"
              :class="[
                activeTab === index
                  ? 'text-white transition-all ease-in rounded-md bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br px-5 py-2.5 text-center w-48'
                  : '',
                'px-4 py-2 focus:outline-none transition-colors ease-in rounded-md w-48 border ml-4',
              ]"
            >
              {{ tab }}
            </button>
          </div>
        </div>
        <div
          class="tab-content w-full border p-4 rounded-md shadow-md border-gray-300"
        >
          <div v-show="activeTab === 0" class="tab-pane py-2 bg-white">
            <div class="w-full">
              <div class="flex justify-start items-center space-x-3 mb-1">
                <div class="flex flex-col">
                  <label class="text-gray-500 text-sm">Поиск:</label>
                  <input
                    type="text"
                    v-model="searchQueryPublished"
                    placeholder="Введите заголовок..."
                    class="px-4 border border-gray-200 rounded-md w-[500px] p-2.5 text-sm text-gray-500"
                  />
                </div>
                <div class="flex-col 2xl:flex hidden">
                  <label for="start-date" class="text-gray-500 text-sm"
                    >От:</label
                  >
                  <input
                    type="date"
                    id="start-date"
                    class="border border-gray-200 text-gray-500 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    v-model="startDate"
                  />
                </div>

                <div class="flex-col 2xl:flex hidden">
                  <label for="start-date" class="text-gray-500 text-sm"
                    >До:</label
                  >
                  <input
                    type="date"
                    id="start-date"
                    class="border border-gray-200 text-gray-500 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    v-model="endDate"
                  />
                </div>

                <div class="flex flex-col" style="margin-left: auto">
                  <label
                    for="start-date"
                    class="text-gray-500 text-sm opacity-0"
                    >Добавить</label
                  >
                  <div class="w-40">
                    <button
                      @click="$router.push({ path: '/addDepoNews' })"
                      class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
                    >
                      Добавить
                    </button>
                  </div>
                </div>
              </div>
              <div class="flex flex-col w-full">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                  >
                    <div
                      class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              class="w-4 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Заголовок
                            </th>
                            <th
                              scope="col"
                              class="w-20 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Обложка
                            </th>
                            <th
                              scope="col"
                              class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Дата
                            </th>
                            <th
                              scope="col"
                              class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Изменить
                            </th>
                            <th
                              scope="col"
                              class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Удалить
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr
                            v-for="(item, index) in paginatedDataPublished"
                            :key="index"
                          >
                            <td
                              class="whitespace-nowrap text-sm text-gray-500 font-mono border-r text-center"
                            >
                              {{ item.id }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                            >
                              <p
                                class="text-base font-bold whitespace-normal text-blue-500 break-all"
                              >
                                {{
                                  item.news.filter(
                                    (e) => e.language === "ru"
                                  )[0].title
                                }}
                              </p>
                            </td>
                            <td
                              class="whitespace-nowrap text-sm text-gray-500 text-center"
                            >
                              <img
                                :src="
                                  item.news.filter(
                                    (e) => e.language === 'ru'
                                  )[0].coverBase64
                                "
                                alt=""
                                class="mx-auto max-h-16"
                              />
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-mono border-x text-center"
                            >
                              {{ formatedDate(item.posted) }}
                            </td>

                            <td
                              @click="getToEdit(item.id, 'published')"
                              class="text-center whitespace-nowrap text-sm text-gray-500 border-l hover:bg-gray-100 hover:text-blue-500 transition-all ease-linear cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-6 h-6 transition ease-linear mx-auto"
                              >
                                <path
                                  d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"
                                />
                                <path
                                  d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"
                                />
                              </svg>
                            </td>
                            <td
                              @click="deleteItem(item.id, 'published')"
                              class="text-center whitespace-nowrap text-sm text-gray-500 border-l hover:bg-gray-100 hover:text-red-500 transition-all ease-linear cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-6 h-6 transition ease-linear mx-auto"
                              >
                                <path
                                  d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zm6.133 2.845a.75.75 0 011.06 0l1.72 1.72 1.72-1.72a.75.75 0 111.06 1.06l-1.72 1.72 1.72 1.72a.75.75 0 11-1.06 1.06L12 15.685l-1.72 1.72a.75.75 0 11-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </td>
                          </tr>
                          <tr v-if="filteredDataPublished.length === 0">
                            <td
                              colspan="2"
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              Запрашиваемый элемент не найден.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="flex flex-col items-end mt-1">
                      <div class="flex text-gray-700 space-x-1">
                        <button
                          @click="previousPage"
                          :disabled="currentPage === 1"
                          class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-left w-6 h-6"
                          >
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <span
                          v-for="pageNumber in pageNumbersPublished"
                          :key="pageNumber"
                          class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md"
                        >
                          <button
                            @click="goToPage(pageNumber)"
                            :disabled="currentPage === pageNumber"
                            :class="{ active: currentPage === pageNumber }"
                            class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>

                        <button
                          @click="nextPage"
                          :disabled="currentPage === totalPagesPublished"
                          class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-right w-6 h-6"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="activeTab === 1" class="tab-pane py-2 bg-white">
            <div class="w-full">
              <div class="flex justify-start items-center space-x-3 mb-1">
                <div class="flex flex-col">
                  <label class="text-gray-500 text-sm">Поиск:</label>
                  <input
                    type="text"
                    v-model="searchQueryPending"
                    placeholder="Введите заголовок..."
                    class="px-4 border border-gray-200 rounded-md w-[500px] p-2.5 text-sm text-gray-500"
                  />
                </div>
              </div>
              <div class="flex flex-col w-full">
                <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div
                    class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                  >
                    <div
                      class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              class="w-4 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Заголовок
                            </th>
                            <th
                              scope="col"
                              class="w-20 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Обложка
                            </th>
                            <th
                              scope="col"
                              class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Дата
                            </th>
                            <th
                              scope="col"
                              class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Изменить
                            </th>
                            <th
                              scope="col"
                              class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Удалить
                            </th>
                            <th
                              scope="col"
                              class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Добавить
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr
                            v-for="(item, index) in paginatedDataPending"
                            :key="index"
                          >
                            <td
                              class="whitespace-nowrap text-sm text-gray-500 font-mono border-r text-center"
                            >
                              {{ item.id }}
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                            >
                              <p
                                class="text-base font-bold whitespace-normal text-blue-500 break-all"
                              >
                                {{
                                  item.news.filter(
                                    (e) => e.language === "ru"
                                  )[0].title
                                }}
                              </p>
                            </td>
                            <td
                              class="whitespace-nowrap text-sm text-gray-500 text-center"
                            >
                              <img
                                :src="
                                  item.news.filter(
                                    (e) => e.language === 'ru'
                                  )[0].coverBase64
                                "
                                alt=""
                                class="mx-auto max-h-16"
                              />
                            </td>
                            <td
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 font-mono border-x text-center"
                            >
                              {{ formatedDate(item.posted) }}
                            </td>

                            <td
                              @click="getToEdit(index, 'pending')"
                              class="text-center whitespace-nowrap text-sm text-gray-500 border-l hover:bg-gray-100 hover:text-blue-500 transition-all ease-linear cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-6 h-6 transition ease-linear mx-auto"
                              >
                                <path
                                  d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"
                                />
                                <path
                                  d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"
                                />
                              </svg>
                            </td>
                            <td
                              @click="deleteItem(item.id, 'pending')"
                              class="text-center whitespace-nowrap text-sm text-gray-500 border-l hover:bg-gray-100 hover:text-red-500 transition-all ease-linear cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-6 h-6 transition ease-linear mx-auto"
                              >
                                <path
                                  d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zm6.133 2.845a.75.75 0 011.06 0l1.72 1.72 1.72-1.72a.75.75 0 111.06 1.06l-1.72 1.72 1.72 1.72a.75.75 0 11-1.06 1.06L12 15.685l-1.72 1.72a.75.75 0 11-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </td>
                            <td
                              @click="approveItem(item.id)"
                              class="text-center whitespace-nowrap text-sm text-gray-500 border-l hover:bg-gray-100 hover:text-green-500 transition-all ease-linear cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                class="w-6 h-6 transition ease-linear mx-auto"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </td>
                          </tr>
                          <tr v-if="filteredDataPending.length === 0">
                            <td
                              colspan="2"
                              class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                            >
                              Запрашиваемый элемент не найден.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="flex flex-col items-end mt-1">
                      <div class="flex text-gray-700 space-x-1">
                        <button
                          @click="previousPage"
                          :disabled="currentPage === 1"
                          class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-left w-6 h-6"
                          >
                            <polyline points="15 18 9 12 15 6"></polyline>
                          </svg>
                        </button>
                        <span
                          v-for="pageNumber in pageNumbersPending"
                          :key="pageNumber"
                          class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md"
                        >
                          <button
                            @click="goToPage(pageNumber)"
                            :disabled="currentPage === pageNumber"
                            :class="{ active: currentPage === pageNumber }"
                            class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md"
                          >
                            {{ pageNumber }}
                          </button>
                        </span>

                        <button
                          @click="nextPage"
                          :disabled="currentPage === totalPagesPending"
                          class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="4"
                            height="4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-right w-6 h-6"
                          >
                            <polyline points="9 18 15 12 9 6"></polyline>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingPage from "./LoadingPage.vue";
import { toast } from "vue3-toastify";
export default {
  beforeRouteEnter(to, from, next) {
    sessionStorage.removeItem("CURRENT_ITEM");
    next();
  },
  components: {
    LoadingPage,
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 7,
      searchQueryPublished: "",
      searchQueryPending: "",
      newsBasePublished: [],
      newsBasePending: [],
      startDate: null,
      endDate: null,
      activeTab: 0,
      tabs: ["Опубликованные", "В ожидании"],
      approvedItem: null,
      loading: true,
    };
  },
  computed: {
    filteredDataPublished() {
      let filteredItems = this.newsBasePublished;

      if (this.startDate && this.endDate) {
        filteredItems = filteredItems.filter((item) => {
          const itemDate = Date.parse(item.posted);
          const startDate = Date.parse(this.startDate);
          const endDate = Date.parse(this.endDate);

          return itemDate >= startDate && itemDate <= endDate;
        });
      }

      if (this.searchQueryPublished) {
        filteredItems = filteredItems.filter((item) => {
          return item.news[0].title
            .toLowerCase()
            .includes(this.searchQueryPublished.toLowerCase());
        });
      }

      return filteredItems;
    },
    filteredDataPending() {
      let filteredItems = this.newsBasePending;

      if (this.searchQueryPending) {
        filteredItems = filteredItems.filter((item) => {
          return item.news[0].title
            .toLowerCase()
            .includes(this.searchQueryPending.toLowerCase());
        });
      }

      return filteredItems;
    },
    paginatedDataPublished() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredDataPublished.slice(startIndex, endIndex);
    },
    paginatedDataPending() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredDataPending.slice(startIndex, endIndex);
    },
    totalPagesPublished() {
      return Math.ceil(this.filteredDataPublished.length / this.itemsPerPage);
    },
    totalPagesPending() {
      return Math.ceil(this.filteredDataPending.length / this.itemsPerPage);
    },
    pageNumbersPublished() {
      const pages = [];
      for (let i = 1; i <= this.totalPagesPublished; i++) {
        pages.push(i);
      }
      return pages;
    },
    pageNumbersPending() {
      const pages = [];
      for (let i = 1; i <= this.totalPagesPending; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    async approveItem(id) {
      try {
        const response = await this.axios.get(
          `/api/Admin/DepoNews/AllowToPublish${id}`,
          this.approvedItem,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          toast.success("Новость успешно добавлена", {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
            toastStyle: {
              fontSize: "14px",
            },
          });
        }
      } catch (error) {
        toast.error("Ошибка в добавлении новости", {
          position: "top-center",
          transition: "flip",
          newestOnTop: true,
          toastStyle: {
            fontSize: "14px",
          },
        });
      }
      finally {
            this.loading = true;
            this.loadTableData();
          }
    },
    async deleteItem(id, type) {
      let deleteId;
      if (type == "published") {
        deleteId = id;
      } else {
        deleteId = id;
      }
      let text = "Подтвердите удаление!";
      try {
        if (confirm(text) == true) {
          // eslint-disable-next-line
          const response = await this.axios.delete(
            `/api/Admin/DepoNews/Delete${deleteId}`
          );
          toast.success("Новость успешно удалена", {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
          });
          this.loadTableData();
        } else {
          text = "Отмена удаления!";
        }
      } catch (error) {
        console.error(error);
      }
    },
    getToEdit(id, type) {
      if (type === "published") {
        const item = this.newsBasePublished.find((item) => item.id === id);
        if (item) {
          sessionStorage.setItem("CURRENT_ITEM", JSON.stringify(item));
          this.$router.push("/addDepoNews");
        }
      } else {
        const item = this.newsBasePending.find((item) => item.id === id);
        if (item) {
          sessionStorage.setItem("CURRENT_ITEM", JSON.stringify(item));
          this.$router.push("/addDepoNews");
        }
      }
    },
    formatedDate(item) {
      const dateTime = new Date(item);
      return dateTime.toLocaleDateString();
    },
    loadTableData() {
        const endpoints = 
        [
            this.axios.get('/api/Admin/DepoNews/GetAllPublished'),
            this.axios.get('api/Admin/DepoNews/GetAllPending')
        ];

        this.axios.all(endpoints)
        .then(this.axios.spread((published, pending) => {
            this.newsBasePublished = published.data.data;
            this.newsBasePending = pending.data.data;
        }))
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            this.loading = false;
        })
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPagesPublished) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  mounted() {
    this.loadTableData();
  },
};
</script>
