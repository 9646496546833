<template>
  <div class="tabs px-5 pt-3">
    <div class="border rounded-md border-gray-300 shadow-sm">
      <div
        class="tab-nav flex justify-between items-center px-5 border-b justify"
      >
        <h2 class="font-bold text-blue-500 text-xl flex items-center">
                <button onclick="window.history.back()" class="pr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clip-rule="evenodd" />
                </svg>
                </button>
          Форма добавления информации
        </h2>
        <div class="py-2.5">
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            @click="activeTab = index"
            :class="[
              activeTab === index
                ? 'text-white transition-all ease-in rounded-md bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br px-5 py-2.5 text-center'
                : '',
              'px-4 py-2 focus:outline-none transition-colors ease-in rounded-md',
            ]"
          >
            {{ tab }}
          </button>
        </div>
      </div>
      <div class="tab-content border-0 px-5">
        <div v-show="activeTab === 0" class="tab-pane py-2 bg-white">
          <label
            for="title"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Заголовок(на русском)</label
          >
          <input
            id="title"
            rows="4"
            class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
            placeholder="Введите заголовок..."
            v-model="this.tableData.informations.filter((e) => e.language === 'ru')[0].header"
          />

          <label
            for="cover"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Загрузка документа(на русском)</label
          >
          <div class="flex justify-center items-center space-x-3">
            <input
              class="pdfRu rounded-md block w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              type="file"
              ref="fileRu"
              @change="uploadFile('fileRu')"
              :class="{
                'border-green-500':
                this.tableData.informations.filter((e) => e.language === 'ru')[0].informationFileId !== null,
              }"
            />
          </div>
        </div>
        <div v-show="activeTab === 1" class="tab-pane py-2 bg-white">
          <label
            for="title"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Заголовок(на узбекском)</label
          >
          <input
            id="title"
            rows="4"
            class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
            placeholder="Введите заголовок..."
            v-model="this.tableData.informations.filter((e) => e.language === 'uz')[0].header"
          />

          <label
            for="cover"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Загрузка документа(на узбекском)</label
          >
          <div class="flex justify-center items-center space-x-3">
            <input
              class="pdfUz rounded-md block w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              type="file"
              ref="fileUz"
              @change="uploadFile('fileUz')"
              :class="{
                'border-green-500':
                this.tableData.informations.filter((e) => e.language === 'uz')[0].informationFileId !== null,
              }"
            />
          </div>
        </div>
        <div v-show="activeTab === 2" class="tab-pane py-2 bg-white">
          <label
            for="title"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Заголовок(на английском)</label
          >
          <input
            id="title"
            rows="4"
            class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
            placeholder="Введите заголовок..."
            v-model="this.tableData.informations.filter((e) => e.language === 'en')[0].header"
          />

          <label
            for="cover"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Загрузка документа(на английском)</label
          >
          <div class="flex justify-center items-center space-x-3">
            <input
              class="pdfEn rounded-md block w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
              type="file"
              ref="fileEn"
              @change="uploadFile('fileEn')"
              :class="{
                'border-green-500':
                this.tableData.informations.filter((e) => e.language === 'en')[0].informationFileId !== null,
              }"
            />
          </div>
        </div>
      </div>
      <div class="border-t border-gray-300 w-full"></div>
      <div class="px-[16px] py-4 rounded-b-md flex bg-gray-200/50">
        <div class="basis-4/5">
          <label
            for="type"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Тип информации</label
          >

          <div class="relative inline-block text-left">
            <div>
              <button
                @click="isOpen = !isOpen"
                class="inline-flex justify-between w-[300px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="options-menu"
                aria-haspopup="true"
                :aria-expanded="isOpen"
              >
                {{ selectedOption }}
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div
              v-show="isOpen"
              class="origin-top-right absolute right-0 mt-2 w-[300px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div
                v-for="(option, index) in options"
                :key="index"
                class=""
                role="none"
              >
                <a
                  @click="selectOption(option.name, option.id)"
                  href="#"
                  class="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  :class="
                    selectedOption === option ? 'bg-gray-100 text-gray-900' : ''
                  "
                >
                  {{ option.name }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="basis-1/5">
          <label
            for="cover"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-end"
            >Год добавления</label
          >
          <div class="flex justify-end items-center space-x-3">
            <div class="relative inline-block text-left">
              <div>
                <button
                  @click="isOpenYear = !isOpenYear"
                  class="inline-flex justify-between w-[300px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  id="options-menu"
                  aria-haspopup="true"
                  :aria-expanded="isOpenYear"
                >
                  {{ selectedYear }}
                  <svg
                    class="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <div
                v-show="isOpenYear"
                class="origin-top-right absolute right-0 mt-2 w-[300px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <div
                  v-for="(option, index) in years"
                  :key="index"
                  class=""
                  role="none"
                >
                  <a
                    @click="selectYear(option)"
                    href="#"
                    class="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    role="menuitem"
                    :class="
                      selectedYear === option ? 'bg-gray-100 text-gray-900' : ''
                    "
                  >
                    {{ option }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex float-right mt-5 space-x-5">
      <div class="w-40">
        <button
          @click="clearForm()"
          class="rounded-md w-full text-white bg-gradient-to-r from-slate-500 via-gray-600 to-gray-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-gray-800 font-medium text-sm px-5 py-2.5 text-center"
        >
          Сбросить
        </button>
      </div>
      <div class="w-40" v-if="isEditable">
        <button
          @click="updateOrAdd()"
          class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
        >
          Изменить
        </button>
      </div>
      <div v-else class="w-40">
        <button
          @click="updateOrAdd()"
          class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
        >
          Опубликовать
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem('CURRENT_TABLE'); 
    sessionStorage.removeItem('CURRENT_STATE'); 
    next();
  },
  data() {
    return {
      tableData: {
        id: 0,
        year: "",
        informationTypeId: null,
        informations: [
          {
            id: 0,
            informationBaseId: 0,
            header: null,
            language: "ru",
            informationFileId: null,
          },
          {
            id: 0,
            informationBaseId: 0,
            header: null,
            language: "uz",
            informationFileId: null,
          },
          {
            id: 0,
            informationBaseId: 0,
            header: null,
            language: "en",
            informationFileId: null,
          },
        ],
      },
      activeTab: 0,
      tabs: ["На русском", "На узбекском", "На английском"],
      isOpen: false,
      selectedOption: "Выберите тип",
      options: null,
      isOpenYear: false,
      selectedYear: "Выберите год",
      years: [],
    };
  },
  methods: {
    selectOption(option, id) {
      this.selectedOption = option;
      this.tableData.informationTypeId = id;
      this.isOpen = false;
    },
    selectYear(option) {
      this.selectedYear = option;
      this.tableData.year = this.selectedYear;
      this.isOpenYear = false;
    },
    async loadDropdownData() {
      this.axios
        .get("api/Admin/Information/GetInformationTypes")
        .then((response) => {
          this.options = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async uploadFile(refName) {
      const file = this.$refs[refName].files[0];
      const formData = new FormData();
      formData.append("file", file);

      const response = await this.axios.post(
        "WebsiteFiles/File/UploadPdf",
        formData
      );
      if (refName == "fileRu") {
        this.tableData.informations.filter((e) => e.language === 'ru')[0].informationFileId = JSON.stringify(
          response.data
        );
      } else if (refName == "fileUz") {
        this.tableData.informations.filter((e) => e.language === 'uz')[0].informationFileId = JSON.stringify(
          response.data
        );
      } else if (refName == "fileEn") {
        this.tableData.informations.filter((e) => e.language === 'en')[0].informationFileId = JSON.stringify(
          response.data
        );
      }
    },
    clearForm() {
      this.$refs.fileRu.value = "";
      this.$refs.fileUz.value = "";
      this.$refs.fileEn.value = "";
      this.tableData = {
        id: 0,
        year: "",
        informationTypeId: null,
        informations: [
          {
            id: 0,
            informationBaseId: 0,
            header: null,
            language: "ru",
            informationFileId: null,
          },
          {
            id: 0,
            informationBaseId: 0,
            header: null,
            language: "uz",
            informationFileId: null,
          },
          {
            id: 0,
            informationBaseId: 0,
            header: null,
            language: "en",
            informationFileId: null,
          },
        ],
        selectedOption: "Выберите тип",
        selectedYear: "Выберите год",
        options: null,
      };
      this.selectOption("Выберите тип", this.tableData.informationTypeId);
      this.selectYear("Выберите год", this.tableData.year);
    },
    async updateOrAdd() {
      try {
        const response = await this.axios.post(
          "/api/Admin/Information/UpdateOrAdd",
          this.tableData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          toast.success("Информация успешно добавлена!", {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
            toastStyle: {
              fontSize: "14px",
            },
          });
          this.clearForm();
          this.$router.push("/allTables")
        }
      } catch (error) {
        toast.error("Ошибка в добавлении информации!", {
          position: "top-center",
          transition: "flip",
          newestOnTop: true,
          toastStyle: {
            fontSize: "14px",
          },
        });
      }
    },
  },
  mounted() {
    this.loadDropdownData();
    const currentYear = new Date().getFullYear();
    const startYear = 2010;
    this.years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, i) => startYear + i
    );

    if (sessionStorage.getItem("CURRENT_TABLE")) {
      this.isEditable = true;
      this.tableData = JSON.parse(sessionStorage.getItem("CURRENT_TABLE"));
      this.selectedOption = JSON.parse(sessionStorage.getItem("CURRENT_STATE"));
      this.selectedYear = this.tableData.year;
    }
  },
};
</script>
