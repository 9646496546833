import { createRouter, createWebHistory } from 'vue-router'
import AddNews from '@/components/AddNews.vue'
import AllNews from '@/components/AllNews.vue'
import AddDepoNews from '@/components/AddDepoNews.vue'
import AllDepoNews from '@/components/AllDepoNews.vue'
import AddDepoMessage from '@/components/AddDepoMessage.vue'
import AllDepoMessages from '@/components/AllDepoMessages.vue'
import AddDepoCompass from '@/components/AddDepoCompass.vue'
import AllDepoCompasses from '@/components/AllDepoCompasses.vue'
import CountersPage from '@/components/CountersPage.vue'
import Dashboard from '../views/DashboardView.vue'
import LoginView from '@/components/LoginView.vue'
import Testing from '@/components/TestingPage.vue'
import AddTable from '@/components/AddTable.vue'
import AllTables from '@/components/AllTables.vue'
import AddTemplate from '@/components/AddTemplate.vue'
import AllTemplates from '@/components/AllTemplates.vue'
import AddGlossary from '@/components/AddGlossary.vue'
import AllGlossaries from '@/components/AllGlossaries.vue'
import HomeStatistic from '@/components/HomeStatistic.vue'
import AddHistory from '@/components/AddHistory.vue'
import AllHistories from '@/components/AllHistories.vue'
import AddNormativeDocumentType from '@/components/AddNormativeDocumentType.vue'
import AllNormativeDocumentTypes from '@/components/AllNormativeDocumentTypes.vue'
import AddNormativeDocument from '@/components/AddNormativeDocument.vue'
import AllNormativeDocuments from '@/components/AllNormativeDocuments.vue'
import AllVacancyDepartmentTypes from '@/components/AllVacancyDepartmentTypes.vue'
import AddVacancyDepartmentType from '@/components/AddVacancyDepartmentType.vue'
import AllVacancy from '@/components/AllVacancy.vue'
import AddVacancy from '@/components/AddVacancy.vue'
import ApplicationForVacancies from '@/components/ApplicationForVacancies.vue'
import AllVideos from '@/components/AllVideos.vue'
import AddVideos from '@/components/AddVideos.vue'
import AllAudios from '@/components/AllAudios.vue'
import AddAudios from '@/components/AddAudios.vue'
import AllOnlineAppeals from '@/components/AllOnlineAppeal.vue'
import AllInformationTableTypes  from '@/components/AllInformationTableTypes.vue'
import AddInformationTableType  from '@/components/AddInformationTableType.vue'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes : [
    {
      path: '/',
      name: 'authorize',
      component: LoginView
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      children: [
        {
          path: '',
          component: CountersPage
        },
        {
          path: '/allNews',
          component: AllNews
        },
        {
          path: '/addNews',
          component: AddNews
        },
        {
          path: '/allDepoNews',
          component: AllDepoNews
        },
        {
          path: '/addDepoNews',
          component: AddDepoNews
        },
        {
          path: '/allDepoMessages',
          component: AllDepoMessages
        },
        {
          path: '/addDepoMessage',
          component: AddDepoMessage
        },
        {
          path: '/allDepoCompasses',
          component: AllDepoCompasses
        },
        {
          path: '/addDepoCompass',
          component: AddDepoCompass
        },
        {
          path: '/testing',
          component: Testing
        },
        {
          path: '/addTable',
          component: AddTable
        },
        {
          path: '/allTables',
          component: AllTables
        },
        {
          path: '/addTemplate',
          component: AddTemplate
        },
        {
          path: '/allTemplates',
          component: AllTemplates
        },
        {
          path: '/addGlossary',
          component: AddGlossary
        },
        {
          path: '/allGlossaries',
          component: AllGlossaries
        },
        {
          path: '/homeStatistic',
          component: HomeStatistic
        },
        {
          path: '/allHistories',
          component: AllHistories
        },
        {
          path: '/addHistory',
          component: AddHistory
        },
        {
          path: '/allNormativeDocumentTypes',
          component: AllNormativeDocumentTypes
        },
        {
          path: '/addNormativeDocumentType',
          component: AddNormativeDocumentType
        },
        {
          path: '/allNormativeDocuments',
          component: AllNormativeDocuments
        },
        {
          path: '/addNormativeDocument',
          component: AddNormativeDocument
        },
        {
          path: '/allVacancyDepartmentTypes',
          component: AllVacancyDepartmentTypes,
          meta: {roles: ['editor']}
        },
        {
          path: '/addVacancyDepartmentType',
          component: AddVacancyDepartmentType
        },
        {
          path: '/allVacancy',
          component: AllVacancy
        },
        {
          path: '/addVacancy',
          component: AddVacancy
        },
        {
          path: '/applicationForVacancies',
          component: ApplicationForVacancies
        },
        {
          path: '/allVideos',
          component: AllVideos
        },
        {
          path: '/addVideos',
          component: AddVideos
        },
        {
          path: '/allAudios',
          component: AllAudios
        },
        {
          path: '/addAudios',
          component: AddAudios
        },
        {
          path: '/allOnlineAppeals',
          component: AllOnlineAppeals
        },
        {
          path: '/allInformationTableTypes',
          component: AllInformationTableTypes
        },
        {
          path: '/addInformationTableType',
          component: AddInformationTableType
        }
      ],
      beforeEnter(to,from,next){
        if(sessionStorage.getItem("TOKEN")){
          next();
        }
        else{
          console.error('You must be authenticated to access this page');
          router.push({path: '/'})
        }
      },
    },
  ]
})


export default router