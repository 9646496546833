import { createStore } from "vuex";
const store = createStore({
  state: {
    
  },
  getters: {},
  mutations: {},
  actions: {},
});


export default store;
