<template>
    <div class="mx-10 pt-5">
      <div class="wrapper space-y-5">
        <div
          class="flex flex-col border border-blue-500 p-2 space-y-2 rounded-md"
        >
          <div class="">
            <span
              class="bg-blue-500 text-white text font-semibold mr-2 p-1.5 rounded"
              >ADMIN.UZCSD.UZ</span
            >
          </div>
          <div class="flex justify-center space-x-5">
            <div
              class="card p-2 flex flex-col justify-center items-center border border-blue-500 border-dashed rounded-md text-blue-500 w-96 bg-blue-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 003 3h15a3 3 0 01-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125zM12 9.75a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H12zm-.75-2.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zM6 12.75a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5H6zm-.75 3.75a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM6 6.75a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-3A.75.75 0 009 6.75H6z"
                    clip-rule="evenodd"
                  />
                  <path
                    d="M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 01-3 0V6.75z"
                  />
                </svg>
              </div>
              <div class="title">Опубликовано новостей:</div>
              <div class="count text-2xl font-bold">
                {{ this.newsCounter }}
              </div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-blue-500 border-dashed rounded-md text-blue-500 w-96 bg-blue-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.125 3C3.089 3 2.25 3.84 2.25 4.875V18a3 3 0 003 3h15a3 3 0 01-3-3V4.875C17.25 3.839 16.41 3 15.375 3H4.125zM12 9.75a.75.75 0 000 1.5h1.5a.75.75 0 000-1.5H12zm-.75-2.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75zM6 12.75a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5H6zm-.75 3.75a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5H6a.75.75 0 01-.75-.75zM6 6.75a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-3A.75.75 0 009 6.75H6z"
                    clip-rule="evenodd"
                  />
                  <path
                    d="M18.75 6.75h1.875c.621 0 1.125.504 1.125 1.125V18a1.5 1.5 0 01-3 0V6.75z"
                  />
                </svg>
              </div>
              <div class="title">Опубликовано depo новостей:</div>
              <div class="count text-2xl font-bold">
                {{ this.depoNewsCounter }}
              </div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-blue-500 border-dashed rounded-md text-blue-500 w-96 bg-blue-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    d="M11.644 1.59a.75.75 0 01.712 0l9.75 5.25a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.712 0l-9.75-5.25a.75.75 0 010-1.32l9.75-5.25z"
                  />
                  <path
                    d="M3.265 10.602l7.668 4.129a2.25 2.25 0 002.134 0l7.668-4.13 1.37.739a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.71 0l-9.75-5.25a.75.75 0 010-1.32l1.37-.738z"
                  />
                  <path
                    d="M10.933 19.231l-7.668-4.13-1.37.739a.75.75 0 000 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 000-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 01-2.134-.001z"
                  />
                </svg>
              </div>
              <div class="title">Опубликовано depo компассов:</div>
              <div class="count text-2xl font-bold">
                {{ this.depoCompassCounter }}
              </div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-blue-500 border-dashed rounded-md text-blue-500 w-96 bg-blue-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    d="M11.644 1.59a.75.75 0 01.712 0l9.75 5.25a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.712 0l-9.75-5.25a.75.75 0 010-1.32l9.75-5.25z"
                  />
                  <path
                    d="M3.265 10.602l7.668 4.129a2.25 2.25 0 002.134 0l7.668-4.13 1.37.739a.75.75 0 010 1.32l-9.75 5.25a.75.75 0 01-.71 0l-9.75-5.25a.75.75 0 010-1.32l1.37-.738z"
                  />
                  <path
                    d="M10.933 19.231l-7.668-4.13-1.37.739a.75.75 0 000 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 000-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 01-2.134-.001z"
                  />
                </svg>
              </div>
              <div class="title">Опубликовано depo вестников:</div>
              <div class="count text-2xl font-bold">
                {{ this.depoMessageCounter }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col border border-orange-500 p-2 space-y-2 rounded-md"
        >
          <div class="">
            <span
              class="bg-orange-500 text-white text font-semibold mr-2 p-1.5 rounded"
              >UZCSD.UZ</span
            >
          </div>
          <div class="flex space-x-5 justify-center">
            <div
              class="card p-2 flex flex-col justify-center items-center border border-orange-500 border-dashed rounded-md text-orange-500 w-96 bg-orange-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
                    clip-rule="evenodd"
                  />
                  <path
                    d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z"
                  />
                </svg>
              </div>
              <div class="title">Активные пользователи</div>
              <div class="count text-2xl font-bold">745</div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-orange-500 border-dashed rounded-md text-orange-500 w-96 bg-orange-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path d="M19.5 6h-15v9h15V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 006 21h12a.75.75 0 000-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375zm0 13.5h17.25a.375.375 0 00.375-.375V4.875a.375.375 0 00-.375-.375H3.375A.375.375 0 003 4.875v11.25c0 .207.168.375.375.375z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="title">Сеансы:</div>
              <div class="count text-2xl font-bold">1380</div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-orange-500 border-dashed rounded-md text-orange-500 w-96 bg-orange-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                  <path
                    fill-rule="evenodd"
                    d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="title">Просмотры</div>
              <div class="count text-2xl font-bold">14407</div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-orange-500 border-dashed rounded-md text-orange-500 w-96 bg-orange-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z"
                  />
                </svg>
              </div>
              <div class="title">Страна</div>
              <div class="count text-2xl font-bold">Узбекистан</div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col border border-emerald-500 p-2 space-y-2 rounded-md"
        >
          <div class="">
            <span
              class="bg-emerald-500 text-white text font-semibold mr-2 p-1.5 rounded"
              >DEPO.UZCSD.UZ</span
            >
          </div>
          <div class="flex space-x-5 justify-center">
            <div
              class="card p-2 flex flex-col justify-center items-center border border-emerald-500 border-dashed rounded-md text-emerald-500 w-96 bg-emerald-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
                    clip-rule="evenodd"
                  />
                  <path
                    d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z"
                  />
                </svg>
              </div>
              <div class="title">Активные пользователи</div>
              <div class="count text-2xl font-bold">245</div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-emerald-500 border-dashed rounded-md text-emerald-500 w-96 bg-emerald-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path d="M19.5 6h-15v9h15V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 006 21h12a.75.75 0 000-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375zm0 13.5h17.25a.375.375 0 00.375-.375V4.875a.375.375 0 00-.375-.375H3.375A.375.375 0 003 4.875v11.25c0 .207.168.375.375.375z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="title">Сеансы:</div>
              <div class="count text-2xl font-bold">380</div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-emerald-500 border-dashed rounded-md text-emerald-500 w-96 bg-emerald-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                  <path
                    fill-rule="evenodd"
                    d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="title">Просмотры</div>
              <div class="count text-2xl font-bold">2407</div>
            </div>

            <div
              class="card p-2 flex flex-col justify-center items-center border border-emerald-500 border-dashed rounded-md text-emerald-500 w-96 bg-emerald-300/10"
            >
              <div class="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  class="w-8 h-8"
                >
                  <path
                    d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z"
                  />
                </svg>
              </div>
              <div class="title">Страна</div>
              <div class="count text-2xl font-bold">Узбекистан</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      name: [],
      newsCounter: null,
      depoNewsCounter: null,
      depoCompassCounter: null,
      depoMessageCounter: null,
    };
  },
  methods: {
    loadCounters() {
      const endpoints = [
        this.axios.get("/api/Admin/News/GetCount"),
        this.axios.get("/api/Admin/DepoNews/GetCount"),
        this.axios.get("/api/Admin/DepoCompasses/GetCount"),
        this.axios.get("/api/Admin/DepoMessages/GetCount"),
      ];

      this.axios
        .all(endpoints)
        .then(
          this.axios.spread((news, depoNews, compasses, messages) => {
            this.newsCounter = news.data.data;
            this.depoNewsCounter = depoNews.data.data;
            this.depoCompassCounter = compasses.data.data;
            this.depoMessageCounter = messages.data.data;
          })
        )
        .catch((error) => {
          console.log(error);
        })
    },
  },
  mounted() {
    let getName = JSON.parse(sessionStorage.getItem("userData"));
    this.name = getName.name;
    this.loadCounters();
  },
};
</script>
