<template>
    <div class="flex flex-col w-full px-5 pt-3">
    <div class="flex justify-between w-full">
      <div class="relative inline-block text-left mb-3">
            <div>
              <button
                @click="isOpen = !isOpen"
                class="inline-flex justify-between w-[300px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="options-menu"
                aria-haspopup="true"
                :aria-expanded="isOpen"
              >
                {{ selectedOption }}
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div
              v-show="isOpen"
              class="origin-top-right absolute right-0 mt-2 w-[300px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div
                v-for="(option, index) in options"
                :key="index"
                class=""
                role="none"
              >
                <a
                  @click="selectOption(option.ruName, option.code)"
                  href="#"
                  class="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  :class="
                    selectedOption === option ? 'bg-gray-100 text-gray-900' : ''
                  "
                >
                  {{ option.ruName }}
                </a>
              </div>
            </div>
          </div>
      <div
        v-show="this.selectedCode != null"
        class="flex flex-col"
        style="margin-left: auto"
      >
        <div class="w-40">
          <button
            @click="$router.push({ path: '/addTemplate' })"
            class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
          >
            Добавить
          </button>
        </div>
      </div>
    </div>

    <div
      v-show="this.selectedCode == null"
      class="border h-[600px] w-full rounded-md shadow-sm flex justify-center items-center"
    >
      <div class="flex flex-col justify-center items-center space-y-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-12 h-12 text-gray-400 animate-pulse"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
          />
        </svg>
        <p class="text-center text-md text-gray-400">
          Заправшиваемый шаблон не найден. <br />
          Пожалуйста выберите тип клиента из выпадающего списка
        </p>
      </div>
    </div>

    <div v-show="this.selectedCode != null" class="accordion w-full space-y-1">
      
        <div
          class="accordion-content p-1 text-gray-700 bg-white border my-1 border-blue-500 rounded-lg"
        >
          <div class="flex flex-col w-full">
            <div class="overflow-x-auto">
              <div class="align-middle inline-block min-w-full">
                <div
                  class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-100">
                      <tr>
                        <th
                          scope="col"
                          class="px-3 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Название
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Изменить
                        </th>
                        <th
                          scope="col"
                          class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Удалить
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(filterArray, filterIndex) in paginatedData"
                      :key="filterIndex"
                    >
                      <tr
                        v-show="infoArray.language == 'ru'"
                        v-for="(
                          infoArray, arrayIndex
                        ) in filterArray.templates"
                        :key="arrayIndex"
                        class="h-4"
                      >
                        <td
                          class="whitespace-nowrap text-sm text-gray-500 font-mono text-center w-20"
                        >
                          {{ filterArray.id }}
                        </td>
                        <td
                          class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 border-x"
                        >
                          <p
                            class="text-base font-bold whitespace-normal text-gray-500"
                          >
                            {{ infoArray.header }}
                          </p>
                        </td>
                        <td
                          class="text-center whitespace-nowrap text-sm text-gray-500 w-20"
                        >
                          <button
                            @click="getToEdit(filterArray.id)"
                            class="hover:text-blue-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              class="w-6 h-6 transition ease-linear mx-auto"
                            >
                              <path
                                d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"
                              />
                              <path
                                d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"
                              />
                            </svg>
                          </button>
                        </td>

                        <td
                          class="text-center whitespace-nowrap text-sm text-gray-500 border-l w-20"
                        >
                          <button
                            @click="deleteItem(filterArray.id)"
                            class="hover:text-blue-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              class="w-6 h-6 transition ease-linear mx-auto"
                            >
                              <path
                                d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zm6.133 2.845a.75.75 0 011.06 0l1.72 1.72 1.72-1.72a.75.75 0 111.06 1.06l-1.72 1.72 1.72 1.72a.75.75 0 11-1.06 1.06L12 15.685l-1.72 1.72a.75.75 0 11-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tr v-show="accordionItems.length == 0">
                      <td
                        colspan="2"
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        Запрашиваемый шаблон еще не добавлен.
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="flex flex-col items-end mt-1">
                  <div class="flex text-gray-700 space-x-1">
                    <button
                      @click="previousPage"
                      :disabled="currentPage === 1"
                      class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-left w-6 h-6"
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </button>
                    <span
                      v-for="pageNumber in pageNumbers"
                      :key="pageNumber"
                      class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md"
                    >
                      <button
                        @click="goToPage(pageNumber)"
                        :disabled="currentPage === pageNumber"
                        :class="{ active: currentPage === pageNumber }"
                        class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md"
                      >
                        {{ pageNumber }}
                      </button>
                    </span>

                    <button
                      @click="nextPage"
                      :disabled="currentPage === totalPages"
                      class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-chevron-right w-6 h-6"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div v-show="loading">
    <LoadingPage message="Выполняется загрузка ..."></LoadingPage>
    </div>
</template>

<script>
import LoadingPage from "./LoadingPage.vue";
import { toast } from "vue3-toastify";
export default {
  components: {
    LoadingPage
  },
  data() {
    return {
      isOpen: false,
      selectedOption: "Выберите тип клиента",
      selectedCode: null,
      options: null,
      names: [],
      accordionItems: [
        {
          id: null,
          clientTypeId: null,
          templates: [
            {
              id: null,
              templateBaseId:null,
              header: null,
              language: null,
              templateFileId: null,
            },
          ],
        },
      ],
      activeIndex: null,
      currentPage: 1,
      itemsPerPage: 5,
      loading: false
    };
  },
  methods: {
    selectOption(option, code) {
      this.selectedOption = option;
      this.selectedCode = code;
      this.isOpen = false;
      this.names.forEach((item) => {
        if (option === item) {
          this.loadTable(code);
        }
      });
    },
    loadTable(code) {
      this.loading = true;
      this.axios
        .get(`api/Admin/Template/GetTemplatesByCode/${code}`)
        .then((response) => {
          this.accordionItems = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async loadDropdownData() {
      this.axios
        .get("api/Admin/ClientType/GetClientTypes")
        .then((response) => {
          this.options = response.data;
          response.data.forEach((item) => {
            this.names.push(item.ruName);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async deleteItem(id) {
      let text = "Подтвердите удаление!";
      try {
        if (confirm(text) == true) {
          await this.axios.delete(`/api/Admin/Template/Delete${id}`);
          this.loadTable(this.selectedCode);
          toast.success("Шаблон успешно удалена", {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
            toastStyle: {
              fontSize: "14px",
            },
          });
        } else {
          text = "You canceled!";
        }
      } catch (error) {
        console.error(error);
      }
      return true;
    },
    getToEdit(id) {
      const item = this.accordionItems.find((item) => item.id === id);
      sessionStorage.setItem(
        "CURRENT_TABLE",
        JSON.stringify(item)
      );
      sessionStorage.setItem(
        "CURRENT_STATE",
        JSON.stringify(this.selectedOption)
      );
      this.$router.push("/addTemplate");
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
  },
  computed: {
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.accordionItems.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.accordionItems.length / this.itemsPerPage);
    },
    pageNumbers() {
      const pages = [];
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  mounted() {
    this.loadDropdownData();
  },
};
</script>
