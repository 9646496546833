<template>
    <div class="fixed inset-0 z-50 flex items-center justify-center transition-all ease-linear" @click="$emit('close')">
      <div class="absolute inset-0 bg-gray-900 opacity-[85%]"></div>
      <div class="relative bg-white rounded-lg p-5 w-[55%]">
        <div class="flex flex-col justify-between mb-4">
          <div class="flex justify-between space-y-4">
            <div class="flex justify-between items-center mb-2">
              <h2 class="text-lg font-medium">Предварительный просмотр обложки</h2>
              <button @click="$emit('close')" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>
          <div class="image object-cover min-h-[512px] border-2 border-gray-400 border-dashed p-0.5 flex bg-gray-200">
            <img :src="image" alt="" class="shadow-xl mx-auto my-auto max-h-[512px]">
        </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        image: {
        type: String,
        default: "",
      },
    },
    methods: {
      closePopup() {
        this.$emit("close");
      },
    },
  };
  </script>