<template>
    <div class="tabs px-5 pt-3">
      <div class="border rounded-md border-gray-300 shadow-sm">
        <div
          class="tab-nav items-center px-5 py-5 border-b justify"
        >
          <h2 class="font-bold text-blue-500 text-xl flex items-center">
            <button onclick="window.history.back()" class="pr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clip-rule="evenodd" />
                </svg>
                </button>
            Статистики
          </h2>
        </div>
        <div class="tab-content border-0 px-5">
          <div class="tab-pane py-2 bg-white">
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Эмитенты акций</label
            >
            <input
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите заголовок..."
              v-model="this.statistics.issuers"
            />
  
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Депоненты</label
            >
            <input
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите заголовок..."
              v-model="this.statistics.depositors"
            />
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Инвестиционные посредники</label
            >
            <input
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите заголовок..."
              v-model="this.statistics.brokers"
            />
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Инвестиционные фонды</label
            >
            <input
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите заголовок..."
              v-model="this.statistics.investmentFunds"
            />
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Акционеры инвестиционных фондов</label
            >
            <input
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите заголовок..."
              v-model="this.statistics.shareholdersOfInvestmentFunds"
            />
            <label
              for="title"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Доверительные управляющие инвестиционными активами</label
            >
            <input
              id="title"
              rows="4"
              class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2"
              placeholder="Введите заголовок..."
              v-model="this.statistics.trustManagers"
            />
          </div>
        </div>
        
        
      </div>
  
      <div class="flex float-right mt-5 space-x-5">
        <div class="w-40">
          <button
            @click="updateOrAdd()"
            class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
          >
            Изменить
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { toast } from "vue3-toastify";
  export default {
    data() {
      return {
        statistics: {
          id: 0,
          issuers: null,
          depositors: null,
          brokers: null,
          investmentFunds: null,
          shareholdersOfInvestmentFunds: null,
          trustManagers: null
        },
        
      };
    },
    methods: {
      async loadData() {
        this.axios
          .get("api/Admin/Statistic/GetStatistic")
          .then((response) => {
            this.statistics = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      async updateOrAdd() {
        try {
          const response = await this.axios.post(
            "/api/Admin/Statistic/UpdateOrAdd",
            this.statistics,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            toast.success("Успешно изменено!", {
              position: "top-center",
              transition: "flip",
              newestOnTop: true,
              toastStyle: {
                fontSize: "14px",
              },
            });
            this.clearForm();
            this.$router.push("/dashboard")
          }
        } catch (error) {
          toast.error("Ошибка при изменении!", {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
            toastStyle: {
              fontSize: "14px",
            },
          });
        }
      },
    },
    mounted() {
      this.loadData();
    },
  };
  </script>
  