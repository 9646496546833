<template>
  <div class="flex w-screen h-screen">
    <div class="part-one basis-3/5 bg-blue-500">
      <img src="../assets/main.svg" alt="main" class="w-full h-full" />
    </div>
    <div
      class="part-two basis-2/5 flex flex-col justify-between items-center py-5 drop-shadow-sm"
    >
      <div class="flex space-x-4 items-center">
        <img src="../assets/logoCSD.svg" alt="" class="h-14 w-14" />
        <p
          class="text-xs uppercase whitespace-nowrap dark:text-white font-thin"
        >
          панель администратора <br />
          сайта uzcsd.uz
        </p>
      </div>
      <div class="second">
        <div class="header">
          <h1 class="mb-6 text-2xl font-extrabold leading-none tracking-tight">
            Вход в панель
            <span class="text-blue-600 dark:text-blue-500">Администратора</span>
          </h1>
        </div>
        <div class="forms">
          <form @submit.prevent="onSubmit">
            <div class="mb-6">
              <label
                for="username"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Логин</label
              >
              <input
                v-model="userAuth.userName"
                type="text"
                id="email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <p v-if="!isEmailValid" class="text-xs text-red-500 mt-1">
                *Введите корректный e-mail адрес
              </p>
            </div>

            <div class="mb-6">
              <label
                for="password"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Пароль</label
              >
              <input
                v-model="userAuth.password"
                type="password"
                id="password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
              <p v-if="!isPasswordValid" class="text-xs text-red-500 mt-1">
                *Введите корректный пароль
              </p>
            </div>

            <button
              type="submit"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Войти
            </button>
          </form>
        </div>
      </div>
      <div class="third">
        <span class="text-xs"
          >© 2023
          <a href="#" class="hover:underline"
            >Central Securities Depository of Uzbekistan</a
          >. All Rights Reserved.</span
        >
      </div>
    </div>
  </div>
  <div v-show="loading">
    <LoadingPage message="Выполняется вход ..."></LoadingPage>
  </div>
</template>

<script>
import LoadingPage from "./LoadingPage.vue";
import { toast } from "vue3-toastify";
export default {
  components: {
    LoadingPage,
  },
  data() {
    return {
      userAuth: {
        userName: "",
        password: "",
      },
      newData: {
        name: null,
        role: null,
      },
      loading: false,
      isEmailValid: true,
      isPasswordValid: true,
    };
  },
  methods: {
    validateEmail() {
      this.isEmailValid = this.userAuth.userName.length > 8 && this.userAuth.userName.includes("@");
    },
    validatePassword() {
      this.isPasswordValid = this.userAuth.password.length > 8;
    },
    async onSubmit() {
      this.loading = true;
      this.validateEmail();
      this.validatePassword();

      if (this.isEmailValid && this.isPasswordValid) {
        try {
          const res = await this.axios.post("Access/login", this.userAuth);
          if (res.status === 200 || res.status === 201) {
            this.newData.name = res.data.userFullName;
            this.newData.role = res.data.access[0];
            sessionStorage.setItem(this.$globals.TOKEN, res.data.token);
            sessionStorage.setItem("userData", JSON.stringify(this.newData));
            this.$router.push({ path: "/dashboard" });
            this.loading = false;
          } else {
            console.log("Authorization failed");
          }
        } catch (err) {
          console.error(err);
          this.loading = false;
          toast.error("Ошибка при входе!", {
            position: "top-center",
            transition: "flip",
            newestOnTop: true,
            toastStyle: {
              fontSize: "14px",
            },
          });
        }
      }
    },
  },
  watch: {
    'userAuth.userName'() {
      this.validateEmail();
    },
    'userAuth.password'() {
      this.validatePassword();
    },
  },
};
</script>
