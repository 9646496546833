<template>
    <div>
        <div ref="editor" class="h-96 whitespace-normal rounded-md"></div>
        <textarea v-model="content" style="display: none; white-space: normal;"></textarea>
    </div>
</template>

<script>
/* eslint-disable */
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';

export default {
    data() {
        return {
            content: '',
            quill: null
        }
    },
    methods: {
        getEditorContent() {
            return this.quill.root.innerHTML;
        },
        clearEditor() {
            return this.quill.root.innerHTML = null;
        }
    },
    mounted() {
        Quill.register('modules/blotFormatter', BlotFormatter);
        const options = {
            modules: {
                toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'align': [] }],
                    ['link', 'image', 'video'],
                    ['clean'],
                    [{ 'script': 'sub' }, { 'script': 'super' }],
                    [{ 'direction': 'rtl' }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'font': [] }],
                ],
                blotFormatter: {
                    // see config options below
                }
            },
            placeholder: 'Введите содержание новости...',
            theme: 'snow'
        };
        this.quill = new Quill(this.$refs.editor, options);
        this.quill.on('text-change', () => {
            this.content = this.quill.root.innerHTML;
        });
    }
}
</script>

<style>
.ql-toolbar {
    border-radius: 5px;
}
</style>