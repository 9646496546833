<template>
    <router-view />
</template>

<style>
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #3F83F8;
  border-radius: 50px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.dark::-webkit-scrollbar-thumb {
  background-color: #84cc16;
  border-radius: 50px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

* {
    font-family: "Gotham Pro", Gotham, Arial;
  }

@font-face {
    font-family: "Gotham Pro";
    src: local("Gotham-Regular"),
      url(./fonts/GothamPro.ttf) format("truetype")
  }

  @font-face {
    font-family: "Gotham Pro Light";
    src: local("Gotham-Light"),
      url(./fonts/GothamPro-Light.ttf) format("truetype")
  }

  @font-face {
    font-family: "Gotham Pro Medium";
    src: local("Gotham-Medium"),
      url(./fonts/GothamPro-Medium.ttf) format("truetype")
  }

  @font-face {
    font-family: "Gotham Pro Bold";
    src: local("Gotham-Bold"),
      url(./fonts/GothamPro-Bold.ttf) format("truetype")
  }

  @font-face {
    font-family: "Gotham Pro Bold Italic";
    src: local("Gotham-Bold-Italic"),
      url(./fonts/GothamPro-BoldItalic.ttf) format("truetype")
  }

  @font-face {
    font-family: "Gotham Pro Black";
    src: local("Gotham-Black"),
      url(./fonts/GothamPro-Black.ttf) format("truetype")
  }
</style>