<template>
  <div class="tabs p-5">
      <div class="border rounded-md border-gray-300 shadow-sm pb-2">
          <div class="tab-nav flex justify-between items-center px-5 border-b justify">
              <h2 class="font-bold text-blue-500 text-xl flex items-center">
                <button onclick="window.history.back()" class="pr-2 hover:text-blue-700">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clip-rule="evenodd" />
                </svg>
                </button>
                Форма добавления depo вестника
              </h2>
              <div class="py-2.5">
                  <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = index" :class="[
                activeTab === index
                  ? 'text-white transition-all ease-in rounded-md bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br px-5 py-2.5 text-center'
                  : '',
                'px-4 py-2 focus:outline-none transition-colors ease-in rounded-md',
              ]">
                      {{ tab }}
                  </button>
              </div>
          </div>
          <div class="tab-content border-0 px-5">
              <div v-show="activeTab === 0" class="tab-pane py-2 bg-white">
                  <label for="titleRu" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок(на русском)</label>
                  <input id="titleRu" rows="4" class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2" placeholder="Введите заголовок..." v-model="this.compassBase.depos.filter((e) => e.language === 'ru')[0].title" />
  
                  <label for="descRu" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание(на русском)</label>
                  <div class="editor mb-2">
                      <quill-editor ref="editorRu" />
                  </div>

                  <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Загрузка документа(на русском)</label>
                  <div class="flex justify-center items-center space-x-3">
                      <input class="pdfRu rounded-md block w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" ref="fileRu" @change="uploadPDF('fileRu')" :class="{
                  'border-green-500': this.compassBase.depos.filter((e) => e.language === 'ru')[0].pdfId !== null,
                }" />
                <button title="Сбросить файл" @click="deleteFile('fileRu')" class="hover:text-red-500 hover:border-red-500 transition-all ease-linear rounded-md border p-2 border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  </button>
                  </div>
                  <div class="rounded-b-md flex mt-3">
                      <div class="basis-4/5">
                          <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Загрузка обложки(на русском)</label>
                          <div class="flex justify-center items-center space-x-3">
                              <input class="rounded-md block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" ref="coverRu" @change="uploadCover('coverRu')" :class="{ 'border-green-500': this.compassBase.depos.filter((e) => e.language === 'ru')[0].coverId !== null }" />
                              <button @click="showPopup = true" title="Посмотреть обложку" class="hover:text-blue-500 hover:border-blue-500 transition-all ease-linear rounded-md border p-2 border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                      <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                  </svg>
                              </button>
                          </div>
                      </div>
  
                      <div class="basis-1/5">
                          <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-end">Дата добавления</label>
                          <div class="flex justify-end items-center space-x-3">
                              <input type="date" id="date" v-model="this.compassBase.posted" class="bg-gray-50 border rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-gray-300" />
                          </div>
                      </div>
                  </div>
                  <Popup v-if="showPopup" @close="showPopup = false" :image="this.previewRu" class="shadow-2xl"></Popup>
              </div>
              <div v-show="activeTab === 1" class="tab-pane py-2 bg-white">
                  <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок(на узбекском)</label>
                  <input id="title" rows="4" class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2" placeholder="Введите заголовок..." v-model="this.compassBase.depos.filter((e) => e.language === 'uz')[0].title" />
  
                  <label for="desc" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание(на узбекском)</label>
                  <div class="editor mb-2">
                      <quill-editor ref="editorUz" />
                  </div>
  
                  <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Загрузка документа(на узбекском)</label>
                  <div class="flex justify-center items-center space-x-3">
                      <input class="pdfUz rounded-md block w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" ref="fileUz" @change="uploadPDF('fileUz')" :class="{
                  'border-green-500': this.compassBase.depos.filter((e) => e.language === 'uz')[0].pdfId !== null,
                }" />
                <button title="Сбросить файл" @click="deleteFile('fileUz')" class="hover:text-red-500 hover:border-red-500 transition-all ease-linear rounded-md border p-2 border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  </button>
                  </div>
                  <div class="rounded-b-md flex mt-3">
                      <div class="basis-4/5">
                          <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Загрузка обложки(на узбекском)</label>
                          <div class="flex justify-center items-center space-x-3">
                              <input class="rounded-md block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" ref="coverUz" @change="uploadCover('coverUz')" :class="{ 'border-green-500': this.compassBase.depos.filter((e) => e.language === 'uz')[0].coverId !== null }" />
                              <button @click="showPopup = true" title="Посмотреть обложку" class="hover:text-blue-500 hover:border-blue-500 transition-all ease-linear rounded-md border p-2 border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                      <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                  </svg>
                              </button>
                          </div>
                      </div>
                      <div class="basis-1/5">
                          <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-end">Дата добавления</label>
                          <div class="flex justify-end items-center space-x-3">
                              <input type="date" id="date" v-model="this.compassBase.posted" class="bg-gray-50 border rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-gray-300" />
                          </div>
                      </div>
                  </div>
                  <Popup v-if="showPopup" @close="showPopup = false" :image="this.previewUz" class="shadow-2xl"></Popup>
              </div>
              <div v-show="activeTab === 2" class="tab-pane py-2 bg-white">
                  <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок(на английском)</label>
                  <input id="title" rows="4" class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2" placeholder="Введите заголовок..." v-model="this.compassBase.depos.filter((e) => e.language === 'en')[0].title" />
  
                  <label for="desc" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание(на английском)</label>
                  <div class="editor mb-2">
                      <quill-editor ref="editorEn" />
                  </div>
  
                  <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Загрузка документа(на английском)</label>
                  <div class="flex justify-center items-center space-x-3">
                      <input class="pdfEn rounded-md block w-full text-sm text-gray-900 border cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" ref="fileEn" @change="uploadPDF('fileEn')" :class="{
                  'border-green-500': this.compassBase.depos.filter((e) => e.language === 'en')[0].pdfId !== null,
                }" />
                <button title="Сбросить файл" @click="deleteFile('fileEn')" class="hover:text-red-500 hover:border-red-500 transition-all ease-linear rounded-md border p-2 border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  </button>
                  </div>
                  <div class="rounded-b-md flex mt-3">
                      <div class="basis-4/5">
                          <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Загрузка обложки(на английском)</label>
                          <div class="flex justify-center items-center space-x-3">
                              <input class="rounded-md block w-full text-sm text-gray-900 border border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type="file" ref="coverEn" @change="uploadCover('coverEn')" :class="{ 'border-green-500': this.compassBase.depos.filter((e) => e.language === 'en')[0].coverId !== null }" />
                              <button @click="showPopup = true" title="Посмотреть обложку" class="hover:text-blue-500 hover:border-blue-500 transition-all ease-linear rounded-md border p-2 border-gray-300 cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                      <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                  </svg>
                              </button>
                          </div>
                      </div>
  
                      <div class="basis-1/5">
                          <label for="cover" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-end">Дата добавления</label>
                          <div class="flex justify-end items-center space-x-3">
                              <input type="date" id="date" v-model="this.compassBase.posted" class="bg-gray-50 border rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-gray-300" />
                          </div>
                      </div>
                      <Popup v-if="showPopup" @close="showPopup = false" :image="this.previewEn" class="shadow-2xl"></Popup>
                  </div>
              </div>
          </div>
      </div>
  
      <div class="flex mt-5 space-x-5 float-right">
          <div class="w-40">
              <button @click="clearForm()" class="rounded-md w-full text-white bg-gradient-to-r from-slate-500 via-gray-600 to-gray-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-gray-800 font-medium text-sm px-5 py-2.5 text-center">
                  Сбросить
              </button>
          </div>
          <div class="w-40" v-if="isEditable">
              <button @click="updateOrAdd()" class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center">
                  Изменить
              </button>
          </div>
          <div v-else class="w-40">
              <button @click="updateOrAdd()" class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center">
                  Опубликовать
              </button>
          </div>
      </div>
  </div>
  </template>
  
  <script>
  import {
      toast
  } from "vue3-toastify";
  import QuillEditor from "./QuilEditor.vue";
  import Popup from "./PopUp.vue";
  export default {
      beforeRouteLeave(to, from, next) {
          sessionStorage.removeItem('CURRENT_ITEM');
          next();
      },
      components: {
          QuillEditor,
          Popup,
      },
      data() {
          return {
              compassBase: {
                  posted: new Date().toISOString().substr(0, 10),
                  status: "",
                  depos: [{
                          id: 0,
                          compassBaseId: 0,
                          title: null,
                          description: null,
                          language: "ru",
                          pdfId: null,
                          coverId: null,
                          coverBase64: "",
                      },
                      {
                          id: 0,
                          compassBaseId: 0,
                          title: null,
                          description: null,
                          language: "uz",
                          pdfId: null,
                          coverId: null,
                          coverBase64: "",
                      },
                      {
                          id: 0,
                          compassBaseId: 0,
                          title: null,
                          description: null,
                          language: "en",
                          pdfId: null,
                          coverId: null,
                          coverBase64: "",
                      },
                  ],
              },
              previewRu: null,
              previewUz: null,
              previewEn: null,
              activeTab: 0,
              tabs: ["На русском", "На узбекском", "На английском"],
              showPopup: false,
              isEditable: false,
              status: null,
          };
      },
      methods: {
        deleteFile(file){
          if(file == 'fileRu') this.compassBase.depos.filter((e) => e.language === 'ru')[0].pdfId = null, this.$refs.fileRu.value = "";
          if(file == 'fileUz') this.compassBase.depos.filter((e) => e.language === 'uz')[0].pdfId = null, this.$refs.fileUz.value = "";
          if(file == 'fileEn') this.compassBase.depos.filter((e) => e.language === 'en')[0].pdfId = null, this.$refs.fileEn.value = "";
        },
        async uploadCover(refName) {
              const file = this.$refs[refName].files[0];
              const formData = new FormData();
              formData.append("file", file);
  
              const response = await this.axios.post(
                  "WebsiteFiles/File/UploadCover",
                  formData
              );
              if (refName == "coverRu") {
                  this.compassBase.depos.filter((e) => e.language === 'ru')[0].coverId = JSON.stringify(response.data);
                  this.previewRu = file;
                  const reader = new FileReader();
                    reader.onload = (event) => {
                      this.previewRu = event.target.result;
                    };
                    reader.readAsDataURL(file);
              } else if (refName == "coverUz") {
                  this.compassBase.depos.filter((e) => e.language === 'uz')[0].coverId = JSON.stringify(response.data);
                  this.previewUz = file;
                  const reader = new FileReader();
                    reader.onload = (event) => {
                      this.previewUz = event.target.result;
                    };
                    reader.readAsDataURL(file);
              } else if (refName == "coverEn") {
                  this.compassBase.depos.filter((e) => e.language === 'en')[0].coverId = JSON.stringify(response.data);
                  this.previewEn = file;
                  const reader = new FileReader();
                    reader.onload = (event) => {
                      this.previewEn = event.target.result;
                    };
                    reader.readAsDataURL(file);
              }
          },
          async uploadPDF(refName) {
              const file = this.$refs[refName].files[0];
              const formData = new FormData();
              formData.append("file", file);
  
              const response = await this.axios.post(
                  "WebsiteFiles/File/UploadPdf",
                  formData
              );
              if (refName == "fileRu") {
                  this.compassBase.depos.filter((e) => e.language === 'ru')[0].pdfId = JSON.stringify(response.data);
              } else if (refName == "fileUz") {
                  this.compassBase.depos.filter((e) => e.language === 'uz')[0].pdfId = JSON.stringify(response.data);
              } else if (refName == "fileEn") {
                  this.compassBase.depos.filter((e) => e.language === 'en')[0].pdfId = JSON.stringify(response.data);
              }
          },
          loadDescription() {
              let editorRu = this.$refs.editorRu;
              let contentRu = editorRu.getEditorContent();
              this.compassBase.depos.filter((e) => e.language === 'ru')[0].description = contentRu;
  
              let editorUz = this.$refs.editorUz;
              let contentUz = editorUz.getEditorContent();
              this.compassBase.depos.filter((e) => e.language === 'uz')[0].description = contentUz;
  
              let editorEn = this.$refs.editorEn;
              let contentEn = editorEn.getEditorContent();
              this.compassBase.depos.filter((e) => e.language === 'en')[0].description = contentEn;
          },
          clearForm() {
              this.$refs.fileRu.value = "";
              this.$refs.fileUz.value = "";
              this.$refs.fileEn.value = "";
              this.$refs.coverRu.value = "";
              this.$refs.coverUz.value = "";
              this.$refs.coverEn.value = "";
              this.$refs.editorRu.clearEditor();
              this.$refs.editorUz.clearEditor();
              this.$refs.editorEn.clearEditor();
              this.compassBase = {
                  posted: new Date().toISOString().substr(0, 10),
                  status: "",
                  depos: [{
                          id: 0,
                          compassBaseId: 0,
                          title: null,
                          description: null,
                          language: "ru",
                          pdfId: null,
                          coverId: null,
                          coverBase64: "",
                      },
                      {
                          id: 0,
                          compassBaseId: 0,
                          title: null,
                          description: null,
                          language: "uz",
                          pdfId: null,
                          coverId: null,
                          coverBase64: "",
                      },
                      {
                          id: 0,
                          compassBaseId: 0,
                          title: null,
                          description: null,
                          language: "en",
                          pdfId: null,
                          coverId: null,
                          coverBase64: "",
                      },
                  ],
              };
              this.previewRu = null;
              this.previewUz = null;
              this.previewEn = null;
              sessionStorage.removeItem('CURRENT_ITEM');
          },
          async updateOrAdd() {
              this.loadDescription();
  
              try {
                  const response = await this.axios.post(
                      "api/Admin/DepoMessages/UpdateOrAdd",
                      this.compassBase, {
                          headers: {
                              "Content-Type": "application/json",
                          },
                      }
                  );
                  if (response.status === 200 && sessionStorage.getItem('CURRENT_ITEM')) {
                      toast.success("Новость успешно изменена", {
                          position: "top-center",
                          transition: "flip",
                          newestOnTop: true,
                          toastStyle: {
                              fontSize: "14px",
                          },
                      });
                      this.clearForm();
                      this.$router.push('/allDepoMessages')
                  }
                  else {
                    toast.success("Новость успешно добавлена", {
                          position: "top-center",
                          transition: "flip",
                          newestOnTop: true,
                          toastStyle: {
                              fontSize: "14px",
                          },
                      });
                      this.clearForm();
                      this.$router.push('/allDepoMessages')
                  }
              } catch (error) {
                  toast.error("Ошибка в добавлении вестника", {
                      position: "top-center",
                      transition: "flip",
                      newestOnTop: true,
                      toastStyle: {
                          fontSize: "14px",
                      },
                  });
              }
          },
      },
      mounted() {
          if (sessionStorage.getItem("CURRENT_ITEM")) {
              this.isEditable = true;
              this.compassBase = JSON.parse(sessionStorage.getItem("CURRENT_ITEM"));
              this.previewRu = this.compassBase.depos.filter((e) => e.language === 'ru')[0].coverBase64; 
              this.previewUz = this.compassBase.depos.filter((e) => e.language === 'uz')[0].coverBase64;
              this.previewEn = this.compassBase.depos.filter((e) => e.language === 'en')[0].coverBase64;
              this.$refs.editorRu.quill.root.innerHTML =
                  this.compassBase.depos.filter((e) => e.language === 'ru')[0].description;
              this.$refs.editorUz.quill.root.innerHTML =
                  this.compassBase.depos.filter((e) => e.language === 'uz')[0].description;
              this.$refs.editorEn.quill.root.innerHTML =
                  this.compassBase.depos.filter((e) => e.language === 'en')[0].description;
              const dateObj = new Date(this.compassBase.posted);
              const localDate = new Date(
                  dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
              );
              this.compassBase.posted = localDate.toISOString().substr(0, 10);
          }
      },
  };
  </script>
