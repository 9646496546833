<template>
    <div class="w-full px-5 pt-3">
      <div class="flex justify-start items-center space-x-3 mb-1">
        <div class="flex flex-col">
          <label class="text-gray-500 text-sm">Поиск:</label>
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Введите ключевое слово..."
            class="px-4 border border-gray-200 rounded-md w-[500px] p-2.5 text-sm text-gray-500"
          />
        </div>
  
        <div class="flex flex-col" style="margin-left: auto">
          <label for="start-date" class="text-gray-500 text-sm opacity-0"
            >Добавить</label
          >
          <div class="w-40">
            <button
              @click="$router.push({ path: '/addGlossary' })"
              class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center"
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <div class="overflow-x-auto mx-0">
          <div class="py-2 align-middle inline-block min-w-full">
            <div
              class="border overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      class="w-4 px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Ключевое слово
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Описание
                    </th>
                    <th
                      scope="col"
                      class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Изменить
                    </th>
                    <th
                      scope="col"
                      class="w-14 px-3 py-2 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Удалить
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(item, index) in paginatedData"
                    :key="index"
                  >
                    <td
                      class="whitespace-nowrap text-sm text-gray-500 font-mono border-r text-center"
                    >
                      {{ item.id }}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base font-bold whitespace-normal text-blue-500"
                      >
                        {{ item.keyWordRu }}
                      </p>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-x"
                    >
                      <p
                        class="text-base whitespace-normal text-justify"
                      >
                        {{ item.descriptionRu }}
                      </p>
                    </td>  
                    <td
                      class="text-center whitespace-nowrap text-sm text-gray-500 border-l"
                    >
                      <button
                        @click="getToEdit(index)"
                        class="hover:text-blue-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 transition ease-linear mx-auto"
                        >
                          <path
                            d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z"
                          />
                          <path
                            d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z"
                          />
                        </svg>
                      </button>
                    </td>
                    <td
                      class="text-center whitespace-nowrap text-sm text-gray-500 border-l"
                    >
                      <button
                        @click="deleteItem(index)"
                        class="hover:text-blue-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          class="w-6 h-6 transition ease-linear mx-auto"
                        >
                          <path
                            d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v.75c0 1.036.84 1.875 1.875 1.875h17.25c1.035 0 1.875-.84 1.875-1.875v-.75C22.5 3.839 21.66 3 20.625 3H3.375z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M3.087 9l.54 9.176A3 3 0 006.62 21h10.757a3 3 0 002.995-2.824L20.913 9H3.087zm6.133 2.845a.75.75 0 011.06 0l1.72 1.72 1.72-1.72a.75.75 0 111.06 1.06l-1.72 1.72 1.72 1.72a.75.75 0 11-1.06 1.06L12 15.685l-1.72 1.72a.75.75 0 11-1.06-1.06l1.72-1.72-1.72-1.72a.75.75 0 010-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="filteredData.length === 0">
                    <td
                      colspan="2"
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      Запрашиваемый элемент не найден.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex flex-col items-end mt-1">
                    <div class="flex text-gray-700 space-x-1">
                      <button
                        @click="previousPage"
                        :disabled="currentPage === 1"
                        class="h-7 w-7 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-left w-6 h-6"
                        >
                          <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                      </button>
                      <span
                        v-for="pageNumber in pageNumbers"
                        :key="pageNumber"
                        class="flex h-7 font-medium bg-white border transition-all ease-linear rounded-md"
                      >
                        <button
                          @click="goToPage(pageNumber)"
                          :disabled="currentPage === pageNumber"
                          :class="{ active: currentPage === pageNumber }"
                          class="w-7 md:flex justify-center items-center hidden cursor-pointer leading-5 transition duration-150 ease-in rounded-md"
                        >
                          {{ pageNumber }}
                        </button>
                      </span>
  
                      <button
                        @click="nextPage"
                        :disabled="currentPage === totalPages"
                        class="h-7 w-7 ml-1 flex justify-center items-center bg-white border hover:text-blue-500 hover:border-blue-500 transition-all ease-linear cursor-pointer rounded-md"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="4"
                          height="4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-right w-6 h-6"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </button>
                    </div>
                  </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="loading">
    <LoadingPage message="Выполняется загрузка ..."></LoadingPage>
    </div>
  </template>
  
  <script>
  import LoadingPage from "./LoadingPage.vue";
  import { toast } from "vue3-toastify";
  export default {
    beforeRouteEnter(to, from, next) {
      sessionStorage.removeItem("CURRENT_ITEM");
      next();
    },
    components: {
      LoadingPage
    },
    data() {
      return {
        currentPage: 1,
        itemsPerPage: 7,
        searchQuery: "",
        glossary: [],
        loading: true
      };
    },
    computed: {
      filteredData() {
        let filteredItems = this.glossary;
  
        if (this.searchQuery) {
          filteredItems = filteredItems.filter((item) => {
            return item.keyWordRu
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase());
          });
        }
  
        return filteredItems;
      },
      paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        return this.filteredData.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.filteredData.length / this.itemsPerPage);
      },
      pageNumbers() {
        const pages = [];
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
        return pages;
      },
    },
    methods: {
      async deleteItem(index) {
        let text = "Подтвердите удаление!";
        let deleteId = parseInt(this.glossary[index].id);
        try {
          if (confirm(text) == true) {
            // eslint-disable-next-line
            const response = await this.axios.delete(
              `/api/Admin/Glossary/Delete${deleteId}`
            );
            toast.success("Глоссарий успешно удалено", {
              position: "top-center",
              transition: "flip",
              newestOnTop: true,
              toastStyle: {
                fontSize: "14px",
              },
            });
            this.loadTableData();
          } else {
            text = "Отмена удаления!";
          }
        } catch (error) {
          console.error(error);
        }
      },
      getToEdit(index) {
        sessionStorage.setItem(
          "CURRENT_ITEM",
          JSON.stringify(this.glossary[index])
        );
        this.$router.push("/addGlossary");
      },
      async loadTableData() {
        this.axios
          .get("/api/Admin/GetGlossaries")
          .then((response) => {
            this.glossary = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      previousPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      goToPage(pageNumber) {
        this.currentPage = pageNumber;
      },
    },
    mounted() {
      this.loadTableData();
    },
  };
  </script>
  
  <style>
  .active {
    background-color: #3b82f6;
    color: white;
  }
  .btn {
    margin-left: auto;
  }
  </style>
  