<template>
    <div class="tabs p-5">
        <div class="border rounded-md border-gray-300 shadow-sm pb-2">
            <div class="tab-nav flex justify-between items-center w-full px-5 border-b justify">
                <h2 class="font-bold text-blue-500 text-xl flex items-center">
                  <button onclick="window.history.back()" class="pr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8">
                  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-4.28 9.22a.75.75 0 000 1.06l3 3a.75.75 0 101.06-1.06l-1.72-1.72h5.69a.75.75 0 000-1.5h-5.69l1.72-1.72a.75.75 0 00-1.06-1.06l-3 3z" clip-rule="evenodd" />
                  </svg>
                  </button>
                    Форма добавления вакансии
                </h2>
                <div class="py-2.5">
                    <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = index" :class="[
                  activeTab === index
                    ? 'text-white transition-all ease-in rounded-md bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br px-5 py-2.5 text-center'
                    : '',
                  'px-4 py-2 focus:outline-none transition-colors ease-in rounded-md',
                ]">
                        {{ tab }}
                    </button>
                </div>
            </div>
            <div class="tab-content border-0 px-5">
                <div v-show="activeTab === 0" class="tab-pane py-2 bg-white">
                    <label for="titleRu" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок(на русском)</label>
                    <input id="titleRu" rows="4" class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2" placeholder="Введите заголовок..." v-model="this.vacancy.titleRu" />
    
                    <label for="descRu" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание(на русском)</label>
                    <div class="editor mb-2">
                        <quill-editor ref="editorRu" />
                    </div>
                </div>
                <div v-show="activeTab === 1" class="tab-pane py-2 bg-white">
                    <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок(на узбекском)</label>
                    <input id="title" rows="4" class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2" placeholder="Введите заголовок..." v-model="this.vacancy.titleUz" />
    
                    <label for="desc" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание(на узбекском)</label>
                    <div class="editor mb-2">
                        <quill-editor ref="editorUz" />
                    </div>
    
                    
                </div>
                <div v-show="activeTab === 2" class="tab-pane py-2 bg-white">
                    <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Заголовок(на английском)</label>
                    <input id="title" rows="4" class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 mb-2" placeholder="Введите заголовок..." v-model="this.vacancy.titleEn" />
    
                    <label for="desc" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Описание(на английском)</label>
                    <div class="editor mb-2">
                        <quill-editor ref="editorEn" />
                    </div>
    
                    
                    
                </div>
                <div class="px-[16px] pt-4 pl-4 pr-4 bg-gray-200/50">
                  <div class="">
                    <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Тестовая ссылка</label>
                    <input id="title" rows="4" class="rounded-md block p-2.5 w-full text-sm text-gray-900 bg-white border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder="Введите заголовок..." v-model="this.vacancy.linkForTesting" />
                  </div>
                </div>
  
                <div class="px-[16px] py-4 rounded-b-md flex bg-gray-200/50 justify-between">
                <div class="">
                <label
                  for="type"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Тип отдела</label
                >

          <div class="relative inline-block text-left">
            <div>
              <button
                @click="isOpen = !isOpen"
                class="inline-flex justify-between w-[700px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="options-menu"
                aria-haspopup="true"
                :aria-expanded="isOpen"
              >
                {{ selectedOption }}
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div
              v-show="isOpen"
              class="origin-top-right absolute right-0 mt-2 w-[700px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div
                v-for="(option, index) in options"
                :key="index"
                class=""
                role="none"
              >
                <a
                  @click="selectOption(option.nameRu, option.id)"
                  href="#"
                  class="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  :class="
                    selectedOption === option ? 'bg-gray-100 text-gray-900' : ''
                  "
                >
                  {{ option.nameRu }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <label
            for="type"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Status</label
          >

          <div class="relative inline-block text-left">
            <div>
              <button
                @click="isStatusOpen = !isStatusOpen"
                class="inline-flex justify-between w-[200px] rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="options-menu"
                aria-haspopup="true"
                :aria-expanded="isStatusOpen"
              >
                {{ selectedStatusOption }}
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 6.293a1 1 0 011.414 0L10 8.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div
              v-show="isStatusOpen"
              class="origin-top-right absolute right-0 mt-2 w-[200px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div
                v-for="(option, index) in statusOptions"
                :key="index"
                class=""
                role="none"
              >
                <a
                  @click="selectStatusOption(option)"
                  href="#"
                  class="block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  :class="
                    selectedStatusOption === option ? 'bg-gray-100 text-gray-900' : ''
                  "
                >
                  {{ option }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="">
          <label
          for="cover"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start"
          >Дата окончания тестирования тест</label
        >
        <div class="flex justify-end items-start space-x-3">
          <input
            type="date"
            id="date"
            v-model="this.vacancy.dateOfTesting"
            class="bg-white border rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-gray-300 h-10"
          />
        </div>
        </div>
        <div class="">
          <label
          for="cover"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-start"
          >Дата окончания приема заявок</label
        >
        <div class="flex justify-end items-start space-x-3">
          <input
            type="date"
            id="date"
            v-model="this.vacancy.expireDate"
            class="bg-white border rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 border-gray-300 h-10"
          />
        </div>
        </div>
      </div>
        </div>
            </div>
        </div>
    
        <div class="flex float-right mt-5 space-x-5">
            <div class="w-40">
                <button @click="clearForm()" class="rounded-md w-full text-white bg-gradient-to-r from-slate-500 via-gray-600 to-gray-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-slate-300 dark:focus:ring-gray-800 font-medium text-sm px-5 py-2.5 text-center">
                    Сбросить
                </button>
            </div>
            <div class="w-40" v-if="isEditable">
                <button @click="updateOrAdd()" class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center">
                    Изменить
                </button>
            </div>
            <div v-else class="w-40">
                <button @click="updateOrAdd()" class="rounded-md w-full text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium text-sm px-5 py-2.5 text-center">
                    Опубликовать
                </button>
            </div>
        
    </div>
    </template>
    
    <script>
    import {
        toast
    } from "vue3-toastify";
    import QuillEditor from "./QuilEditor.vue";
    export default {
        beforeRouteLeave(to, from, next) {
            sessionStorage.removeItem('CURRENT_VACANCY');
            next();
        },
        components: {
            QuillEditor,
        },
        data() {
            return {
                vacancy: {
                    departmentId:null,
                    dateOfTesting: new Date().toISOString().substr(0, 10),
                    expireDate: new Date().toISOString().substr(0, 10),
                    status: "",
                    titleRu: null,
                    textRu: null,
                    titleUz: null,
                    textUz: null,
                    titleEn: null,
                    textEn: null,
                    linkForTesting:null,
                    departmentNameRu: null
                },
                activeTab: 0,
                options: null,
                isOpen: false,
                selectedOption: "Выберите тип",
                statusOptions: null,
                isStatusOpen: false,
                selectedStatusOption: "Выберите статус",
                tabs: ["На русском", "На узбекском", "На английском"],
                isEditable: false,
            };
        },
        methods: {
            selectOption(option, id) {
            this.selectedOption = option;
            this.vacancy.departmentId = id;
            this.isOpen = false;
            },
            selectStatusOption(option) {
            this.selectedStatusOption = option;
            this.vacancy.status = option;
            this.isStatusOpen = false;
            },
            async loadDropdownData() {
            this.axios
                .get("api/Admin/Department/GetDepartments")
                .then((response) => {
                this.options = response.data;
                })
                .catch((error) => {
                console.log(error);
                });
            },
            async loadStatusData() {
            this.axios
                .get("api/Admin/Vacancy/GetStatuses")
                .then((response) => {
                this.statusOptions = response.data;
                })
                .catch((error) => {
                console.log(error);
                });
            },
            
            loadDescription() {
                let editorRu = this.$refs.editorRu;
                let contentRu = editorRu.getEditorContent();
                this.vacancy.textRu = contentRu;
    
                let editorUz = this.$refs.editorUz;
                let contentUz = editorUz.getEditorContent();
                this.vacancy.textUz = contentUz;
    
                let editorEn = this.$refs.editorEn;
                let contentEn = editorEn.getEditorContent();
                this.vacancy.textEn = contentEn;
            },
            clearForm() {
                this.$refs.editorRu.clearEditor();
                this.$refs.editorUz.clearEditor();
                this.$refs.editorEn.clearEditor();
                this.vacancy = {
                    departmentId:null,
                    dateOfTesting: new Date().toISOString().substr(0, 10),
                    expireDate: new Date().toISOString().substr(0, 10),
                    status: "",
                    titleRu: null,
                    textRu: null,
                    titleUz: null,
                    textUz: null,
                    titleEn: null,
                    textEn: null,
                    linkForTesting:null,
                    departmentNameRu: null
                };
                this.selectedOption = "Выберите тип";
                this.selectStatusOption = "Выберите статус";
                sessionStorage.removeItem('CURRENT_VACANCY');
            },
            async updateOrAdd() {
                this.loadDescription();
                try {
                    const response = await this.axios.post(
                        "api/Admin/Vacancy/UpdateOrAdd",
                        this.vacancy, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    );
                    if (response.status === 200) {
                        toast.success("Вакансия успешно добавлена/изменена", {
                            position: "top-center",
                            transition: "flip",
                            newestOnTop: true,
                            toastStyle: {
                                fontSize: "14px",
                            },
                        });
                        this.clearForm();
                        this.$router.push('/allVacancy')
                    }
                } catch (error) {
                    toast.error("Ошибка в добавлении вакансии", {
                        position: "top-center",
                        transition: "flip",
                        newestOnTop: true,
                        toastStyle: {
                            fontSize: "14px",
                        },
                    });
                }
            },
        },
        mounted() {
            this.loadDropdownData();
            this.loadStatusData();

            if (sessionStorage.getItem("CURRENT_VACANCY")) {
                this.loadDropdownData();
                this.isEditable = true;
                this.vacancy = JSON.parse(sessionStorage.getItem("CURRENT_VACANCY"));
                this.$refs.editorRu.quill.root.innerHTML =
                    this.vacancy.textRu;
                this.$refs.editorUz.quill.root.innerHTML =
                    this.vacancy.textUz;
                this.$refs.editorEn.quill.root.innerHTML =
                    this.vacancy.textEn;

                const dateObj = new Date(this.vacancy.expireDate);
                const localDate = new Date(
                    dateObj.getTime() - dateObj.getTimezoneOffset() * 60000
                );
                this.vacancy.expireDate = localDate.toISOString().substr(0, 10);
                const dateObj1 = new Date(this.vacancy.dateOfTesting);
                const localDate1 = new Date(
                    dateObj1.getTime() - dateObj1.getTimezoneOffset() * 60000
                );
                this.vacancy.dateOfTesting = localDate1.toISOString().substr(0, 10);

                this.selectedOption = this.vacancy.departmentNameRu;
                this.selectStatusOption(this.vacancy.status);
            }
        },
    };
    </script>
  